import axios from 'axios';
import * as API from '../../ApiUrl';
import { GET_PROFILE, SET_PROFILE, GET_PROFILE_FAIL, SET_USER_ROLE, SET_IS_SINGLE_WALLET, SET_USER_PAYSTRING } from '../actionTypes';
import { merchantApi } from '../../redux/api/apiConfig';
import { toast } from 'react-toastify';

let token = localStorage.getItem('token');

const config = {
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  },
};

export const setUserRole = (userRole) => async (dispatch) => {
  dispatch({
    type: SET_USER_ROLE,
    payload: userRole,
  });
};

export const setUserPayString = () => async (dispatch) => {
  console.log('fed');
  merchantApi
    .get(API.PAY_STRING)
    .then((response) => {
      const { data } = response;
      console.log(data);
      if (data?.data?.paystring) {
        dispatch({
          type: SET_USER_PAYSTRING,
          payload: data.data.paystring,
        });
        return;
      }
    })
    .catch((error) => {
      toast.error(error?.data?.data?.message || 'Something went wrong');
    });
};

export const setSingleWallet = () => async (dispatch) => {
  merchantApi
    .get(API.CONFIG)
    .then((response) => {
      const { data } = response;
      console.log(data);
      if (data.data.store_wise_wallet === null || data.data.store_wise_wallet === undefined) {
        dispatch({
          type: SET_IS_SINGLE_WALLET,
          payload: data.data.store_wise_wallet,
        });
      } else {
        dispatch({
          type: SET_IS_SINGLE_WALLET,
          payload: !data.data.store_wise_wallet,
        });
      }
    })
    .catch((error) => {
      toast.error(error?.data?.data?.message || 'Something went wrong');
    });
};

export const getProfile = () => async (dispatch) => {
  dispatch({ type: GET_PROFILE });

  const token = localStorage.getItem('token');
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  try {
    const res = await axios.get(`${API.USER_PROFILE}`, config);
    dispatch({
      type: SET_PROFILE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: GET_PROFILE_FAIL,
    });
  }
};

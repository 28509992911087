import React from 'react';

const CustomTooltip = ({ active, payload, label, valueLabel }) => {
  if (active && payload && payload.length) {
    return (
      <div className='custom-tooltip recharts-default-tooltip custom-tooltip-stb'>
        <p className='label'>{label}</p>
        <p className='intro'>{`${valueLabel} : ${valueLabel === 'Value' ? '$' : ''} ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;

import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
// @mui
import { Box, Stack, Typography, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem } from '@mui/material';
import { Button } from 'rsuite';

import ButtonPrimary from '../ButtonPrimary';

import * as yup from 'yup';
import { useFormik } from 'formik';
import TextFieldSelect from '../TextFieldSelect';
import TextFieldComponent from '../TextFieldComponent';
import { Upload } from '../upload';
import { SENDER_DOCUMENT } from '../../ApiUrl';
import axios from 'axios';
import { toast } from 'react-toastify';
import { allowedMediaTypes } from '../../utils/common';

export default function UploadDocument({ isOpen, onClose, senderId, getSenderAccounts }) {
  const yupObject = {
    name: yup.string('Enter a name').required('Document name is required.'),

    document_type: yup.string('Select a document type').required('Document type is required.'),
    document: yup.mixed('Select a document').required('Document image is required.'),
  };

  const validationSchema = yup.object(yupObject);
  const formik = useFormik({
    initialValues: {},
    validateOnBlur: true,
    validationSchema: validationSchema,
    validateOnMount: true,
    // validateOnChange: true,
  });

  const { kycDocTypes } = useSelector((state) => state.configReducer);
  const [loading, setLoading] = useState(false);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      console.log(acceptedFiles[0]);
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        formik.setFieldValue('document', newFile);
      }
    },
    [formik]
  );

  const handleRemoveFile = () => {
    formik.setFieldValue('document', null);
  };

  const uploadClickHandler = async () => {
    setLoading(true);
    const { document_type, document, name } = formik.values;
    let fileData = new FormData();
    fileData.append('document', document);
    fileData.append('document_type', parseInt(document_type));
    fileData.append('sender', senderId);
    fileData.append('name', name);

    let token = localStorage.getItem('token');
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW',
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.post(SENDER_DOCUMENT, fileData, config);

      setLoading(false);
      getSenderAccounts(response.data.data);
      toast.success('Document updated successfully');
      onClose();
    } catch (error) {
      console.log(error);

      toast.error(error?.data?.data?.message || 'Something went wrong');
      // toast.error('Document update failed');
      setLoading(false);
    }
  };

  const handleCloseModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose();
  };
  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={'md'}
      onClose={handleCloseModal}
      scroll={'body'}
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
        },
      }}
    >
      <DialogTitle>Upload Document</DialogTitle>
      <DialogContent sx={{ paddingTop: '20px !important', paddingBottom: '0 !important' }}>
        <Box
          sx={{
            display: 'grid',
            columnGap: '16px',
            rowGap: '12px',
            marginBottom: '12px',
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
          }}
        >
          <TextFieldSelect
            formik={formik}
            name='document_type'
            type='text'
            label='Document Type'
            options={kycDocTypes}
            render={(doc) => <MenuItem value={doc.id}>{doc.name}</MenuItem>}
          />
          <TextFieldComponent formik={formik} name={'name'} label={'Document Name'} type={'text'} />

          <Stack
            direction={'column'}
            sx={{
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              flex: 1,
            }}
            spacing={0.5}
          >
            <Typography>Upload</Typography>
            <Upload
              accept={allowedMediaTypes}
              name='document'
              maxSize={3145728}
              onDrop={handleDrop}
              onDelete={handleRemoveFile}
              file={formik.values.document}
              error={formik.touched.document && Boolean(formik.errors.document)}
              helperText={formik.touched.document && formik.errors.document}
            />
          </Stack>
        </Box>

        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button className='btn white-btn action-button basis-30' onClick={onClose} appearance='subtle'>
            Cancel
          </Button>

          <ButtonPrimary onClick={uploadClickHandler} disabled={!formik.isValid} loading={loading}>
            Upload
          </ButtonPrimary>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

// ----------------------------------------------------------------------

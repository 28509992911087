import React, { useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Nav, Badge, Image, Button, Dropdown, Accordion, Navbar } from '@themesberg/react-bootstrap';
import { Divider, IconButton, Stack, useMediaQuery } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import { CgHome } from 'react-icons/cg';
import { connect, useDispatch, useSelector } from 'react-redux';

import stblogo from '../assets/img/icons/stblogo.svg';
import ProfilePicture from '../assets/img/team/profile-picture-3.jpg';
import HomeIcon from '../assets/img/sideicon/home.svg';
import AdminIcon from '../assets/img/sideicon/adminstration.svg';
import LedgerIcon from '../assets/img/sideicon/ledger.svg';
import TransIcon from '../assets/img/sideicon/transaction.svg';
import ProfileIcon from '../assets/img/sideicon/profile.svg';
import liquidityIcon from '../assets/img/sideicon/liquidity.svg';
import ExchangeIcon from '../assets/img/sideicon/exchange.svg';
import BankIcon from '../assets/img/sideicon/bank.svg';
import LevelIcon from '../assets/img/sideicon/level.svg';
import CurrencyIcon from '../assets/img/sideicon/currency.svg';
import InvoiceIcon from '../assets/img/sideicon/invoice.svg';
import ClientsIcon from '../assets/img/sideicon/clients.svg';
import SendersIcon from '../assets/img/sideicon/senders.svg';
import ConfigIcon from '../assets/img/sideicon/configuration.svg';
import FeesIcon from '../assets/img/sideicon/fees.svg';
import ReceiversIcon from '../assets/img/sideicon/receivers.svg';
import SupportIcon from '../assets/img/sideicon/support.svg';
import BusinessIcon from '../assets/img/sideicon/business.svg';
import IndividualIcon from '../assets/img/sideicon/individual.svg';
import KycIcon from '../assets/img/sideicon/kyc.svg';
import supportChatIcon from '../assets/img/sideicon/supportChat.svg';
import OutwardIcon from '../assets/img/sideicon/outward.svg';
import InwardIcon from '../assets/img/sideicon/inward.svg';
import DepositIcon from '../assets/img/sideicon/deposit.svg';
import PortalIcon from '../assets/img/sideicon/portal.svg';
import WalletIcon from '../assets/img/sideicon/wallet.svg';
import TransactionsIcon from '../assets/img/sideicon/transactions.svg';
import PortalUsersIcon from '../assets/img/sideicon/portalUsers.svg';
import RedemptionIcon from '../assets/img/sideicon/availableRedemption.svg';
import FeesLogo from '../assets/img/sideicon/feeslogo.svg';
import { Routes } from '../routes';
import { getStatus } from '../redux/actions/kybActions';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { toggleSidebar, showSidebar } from '../redux/actions/config';
import Scrollbar from './Scrollbar';

const Sidebar = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { showSidebar: isShowSidebar } = useSelector((state) => state.configReducer);

  const role = useSelector((state) => state.profileReducer?.user?.role?.key);
  const { permission, permissionLoading } = useSelector((state) => state.profileReducer);

  const matches = useMediaQuery('(min-width:768px)');

  useEffect(() => {
    dispatch(showSidebar());
  }, [matches, dispatch]);

  const { pathname } = location;
  console.log(pathname);
  const [show, setShow] = useState(false);
  const showClass = show ? 'show' : '';

  const onCollapse = () => setShow(!show);

  const CollapsableNavItem = (props) => {
    const { eventKey, title, image, children = null, type = '' } = props;
    const defaultKey = pathname.indexOf(eventKey) !== -1 ? eventKey : '';

    return (
      <Accordion as={Nav.Item} defaultActiveKey={defaultKey}>
        <Accordion.Item eventKey={eventKey}>
          <Accordion.Button as={Nav.Link} className='d-flex justify-content-between border-none box-shad-none align-items-center'>
            <span>
              {image ? <Image src={image} width={20} height={20} className='sidebar-icon svg-icon' /> : null}
              <span className='sidebar-text light_color colr font-fam'>{title}</span>
            </span>
          </Accordion.Button>
          <Accordion.Body className='multi-level pt-0'>
            <Nav style={{ marginLeft: type === 'nested' ? '2rem' : '' }} className='flex-column bgchild  '>
              {children}
            </Nav>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const NavItem = (props) => {
    const { title, link, external, target, icon, image, badgeText, badgeBg = 'secondary', badgeColor = 'primary', subTitle = '' } = props;
    const classNames = badgeText ? 'd-flex justify-content-start   align-items-center justify-content-between ' : '';
    let navItemClassName;
    // if (link === '/') {
    navItemClassName = pathname === link ? 'active' : '';
    // } else {
    //   navItemClassName = pathname.includes(link) ? 'active' : '';
    // }
    const linkProps = external ? { href: link } : { as: Link, to: link };

    return (
      <Nav.Item className={navItemClassName} onClick={() => setShow(false)}>
        <Nav.Link {...linkProps} target={target} className={classNames}>
          <span className='d-flex'>
            {icon ? (
              <span className='sidebar-icon'>
                <CgHome size='1em' />{' '}
              </span>
            ) : null}
            {image ? <Image src={image} width={20} height={20} className='sidebar-icon svg-icon' /> : null}
            <span className='d-flex row'>
              <span className='sidebar-text light_color font-fam'>{title}</span>
              <span className='light_color font-11'>{subTitle}</span>
            </span>
          </span>
          {badgeText ? (
            <Badge pill bg={badgeBg} text={badgeColor} className='badge-md notification-count ms-2'>
              {badgeText}
            </Badge>
          ) : null}
        </Nav.Link>
      </Nav.Item>
    );
  };

  let sidebarContent = (
    <>
      <Navbar expand={false} collapseOnSelect variant='dark' className='navbar-theme-primary px-4 d-md-none'>
        <Navbar.Toggle as={Button} aria-controls='main-navbar' onClick={onCollapse}>
          <span className='navbar-toggler-icon' />
        </Navbar.Toggle>
      </Navbar>
      <CSSTransition timeout={300} in={show} classNames='sidebar-transition'>
        <Scrollbar className={`collapse ${showClass} sidebar d-md-block primary_color text-white ${!isShowSidebar ? 'sidebar-hide' : ''}`}>
          <div className='sidebar-inner px-4 pt-3'>
            <div className='user-card d-flex d-md-none align-items-center justify-content-between justify-content-md-center pb-4'>
              <div className='d-flex align-items-center'></div>
              <Nav.Link className='collapse-close d-md-none' onClick={onCollapse}>
                <FontAwesomeIcon icon={faTimes} />
              </Nav.Link>
            </div>
            <Nav className='flex-column pt-3 pt-md-0 '>
              <Stack
                sx={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  mb: 5,
                }}
              >
                <img src={stblogo} alt='' />
                {matches && (
                  <IconButton
                    aria-label='toggle passphrase visibility'
                    onClick={() => {
                      dispatch(toggleSidebar());
                    }}
                    edge='end'
                    sx={{
                      color: 'white',
                    }}
                  >
                    {<KeyboardArrowLeftIcon />}
                  </IconButton>
                )}
              </Stack>
              {permission?.dashboard?.permission?.visible[role] && <NavItem title='Home' link={Routes.DashboardOverview.path} image={HomeIcon} />}

              {permission?.administration?.permission?.visible[role] && (
                <CollapsableNavItem eventKey='administration/' title='Administration' image={AdminIcon}>
                  {permission?.administration?.portalUsers?.permission?.visible[role] && (
                    <NavItem title='Portal Users' image={PortalUsersIcon} link={Routes.SystemUser.path} />
                  )}
                </CollapsableNavItem>
              )}
              {permission?.reports?.permission?.visible[role] && (
                <CollapsableNavItem eventKey='reports/' title='Reports' image={TransIcon}>
                  {permission?.reports?.transactions?.permission?.visible[role] && (
                    <NavItem title='Transaction Report' image={TransactionsIcon} link={Routes.Transaction.path} />
                  )}
                  {/* {permission?.reports?.mtoLedgerDetails?.permission?.visible[
                    role
                  ] && (
                    <NavItem
                      title="MTO Ledger Details"
                      image={LedgerIcon}
                      link={Routes.MTOLedgerDetails.path}
                    />
                  )}

                  {permission?.reports?.walletMovement?.permission?.visible[
                    role
                  ] && (
                    <NavItem
                      title="Wallet Movement"
                      image={WalletIcon}
                      link={Routes.WalletMovement.path}
                    />
                  )}
                  {permission?.reports?.mtoLedgerSummary?.permission?.visible[
                    role
                  ] && (
                    <NavItem
                      title="MTO Ledger Summary"
                      image={LedgerIcon}
                      link={Routes.MTOLedgerSummary.path}
                    />
                  )} */}
                  {permission?.reports?.depositReport?.permission?.visible[role] && (
                    <NavItem title='Deposit Report' image={DepositIcon} link={Routes.DepositReport.path} />
                  )}
                  {permission?.reports?.outwardRemittanceReport?.permission?.visible[role] && (
                    <NavItem title='Outward Remittance Report' image={InvoiceIcon} link={Routes.OutwardRemittanceReport.path} />
                  )}
                  {permission?.reports?.inwardRemittanceReport?.permission?.visible[role] && (
                    <NavItem title='Inward Remittance Report' image={InvoiceIcon} link={Routes.InwardRemittanceReport.path} />
                  )}
                  {permission?.reports?.redemptionReport?.permission?.visible[role] && (
                    <NavItem title='Settlement Report' image={InvoiceIcon} link={Routes.RedemptionReport.path} />
                  )}
                  {permission?.reports?.pendingRedemptionReport?.permission?.visible[role] && (
                    <NavItem title='Available Settlement Report' image={InvoiceIcon} link={Routes.AvailableRedemptionReport.path} />
                  )}
                </CollapsableNavItem>
              )}
              {permission?.clients?.permission?.visible[role] && (
                <CollapsableNavItem eventKey='clients/' title='Clients' image={ClientsIcon}>
                  <>
                    {permission?.clients?.senders?.permission?.visible[role] && (
                      <CollapsableNavItem eventKey='clients/senders/' title='Senders' image={SendersIcon} type='nested'>
                        <>
                          {permission?.clients?.senders?.individual?.permission?.visible[role] && (
                            <NavItem title='Individual' image={IndividualIcon} link={Routes.SenderIndividualClients.path} />
                          )}
                          {permission?.clients?.senders?.business?.permission?.visible[role] && (
                            <NavItem title='Business' image={BusinessIcon} link={Routes.SenderBusinessClients.path} />
                          )}
                          {permission?.clients?.senders?.deposits?.permission?.visible[role] && (
                            <NavItem title='Deposits' image={DepositIcon} link={Routes.Deposits.path} />
                          )}
                          {permission?.clients?.senders?.userKyc?.permission?.visible[role] && (
                            <NavItem title='KYC' image={KycIcon} link={Routes.UserKyc.path} />
                          )}
                        </>
                      </CollapsableNavItem>
                    )}
                    {permission?.clients?.receivers?.permission?.visible[role] && (
                      <CollapsableNavItem eventKey='clients/receivers/' title='Receivers' image={ReceiversIcon} type='nested'>
                        <>
                          {permission?.clients?.receivers?.individual?.permission?.visible[role] && (
                            <NavItem title='Individual' image={IndividualIcon} link={Routes.ReceiverIndividualClients.path} />
                          )}
                          {permission?.clients?.receivers?.business?.permission?.visible[role] && (
                            <NavItem title='Business' image={BusinessIcon} link={Routes.ReceiverBusinessClients.path} />
                          )}
                        </>
                      </CollapsableNavItem>
                    )}
                  </>
                </CollapsableNavItem>
              )}

              {/* <NavItem
                title="User Chat"
                image={KycIcon}
                link={Routes.UserChatGeneral.path}
              /> */}
              {permission?.remittances?.permission?.visible[role] && (
                <CollapsableNavItem eventKey='remittance/' title='Remittances' image={InvoiceIcon}>
                  <>
                    {permission?.remittances?.outward?.permission?.visible[
                      role
                    ] && (
                      <CollapsableNavItem
                        eventKey="remittance/outward"
                        title="Outward"
                        image={OutwardIcon}
                        type="nested"
                      >
                        <>
                          {permission?.remittances?.outward?.allremittance?.permission?.visible[role] && (
                            <NavItem title='All Remittances' image={PortalIcon} link={Routes.OutwardRemittance.path} />
                          )}
                          {permission?.remittances?.outward?.pendingremittance?.permission?.visible[role] && (
                            <NavItem title='Pending Customer Remittances' image={ClientsIcon} link={Routes.ClientRemittance.path} />
                          )}
                        </>
                      </CollapsableNavItem>
                    )}
                    {permission?.remittances?.inward?.permission?.visible[role] && (
                      <NavItem title='Inward' image={InwardIcon} link={Routes.InwardRemittance.path} />
                    )}
                  </>
                </CollapsableNavItem>
              )}
              {permission?.redemptions?.permission?.visible[role] && (
                <CollapsableNavItem eventKey='redemptions/' title='Settlements' image={InvoiceIcon}>
                  <>
                    {permission?.redemptions?.availableRedemptions?.permission?.visible[role] && (
                      <NavItem title='Available Settlements' image={RedemptionIcon} link={Routes.AvailableRedemptions.path} />
                    )}
                    {permission?.redemptions?.requestsRaised?.permission?.visible[role] && (
                      <NavItem title='Requests Raised' image={InwardIcon} link={Routes.RedemptionsRaised.path} />
                    )}
                    {permission?.redemptions?.requestsReceived?.permission?.visible[role] && (
                      <NavItem title='Requests Received' image={OutwardIcon} link={Routes.RedemptionsReceived.path} />
                    )}
                  </>
                </CollapsableNavItem>
              )}
              {permission?.configurations?.permission?.visible[role] && (
                <CollapsableNavItem eventKey='configurations/' title='Configurations' image={ConfigIcon}>
                  <>
                    {permission?.configurations?.fees?.permission?.visible[role] && (
                      <CollapsableNavItem eventKey='configurations/fees/' title='Fees' image={FeesIcon} type='nested'>
                        <>
                          {permission?.configurations?.fees?.inwardRemittanceFees?.permission?.visible[role] && (
                            <NavItem title='Inward Remittance Fees' image={FeesLogo} link={Routes.InwardRemittanceFees.path} />
                          )}
                          {permission?.configurations?.fees?.outwardRemittanceFees?.permission?.visible[role] && (
                            <NavItem title='Outward Remittance Fees' image={FeesLogo} link={Routes.OutwardRemittanceFees.path} />
                          )}
                        </>
                      </CollapsableNavItem>
                    )}
                    {permission?.configurations?.exchangeOffers?.permission?.visible[role] && (
                      <NavItem title='Exchange Offers' image={ExchangeIcon} link={Routes.ExchangeOffers.path} />
                    )}
                    <NavItem title='MTO Financial Accounts' image={BankIcon} link={Routes.MtoFinancialAccounts.path} />
                    {permission?.configurations?.kycLevels?.permission?.visible[role] && (
                      <NavItem title='KYC Levels' image={LevelIcon} link={Routes.KycLevels.path} />
                    )}
                  </>
                </CollapsableNavItem>
              )}
              {permission?.accounts?.permission?.visible[role] && (
                <CollapsableNavItem eventKey='accounts/' title='Accounts' image={InvoiceIcon}>
                  <>
                    {permission?.accounts?.account?.permission?.visible[role] && (
                      <NavItem title='Accounts' image={ProfileIcon} link={Routes.Accounts.path} />
                    )}
                    {permission?.accounts?.liquidity?.permission?.visible[role] && (
                      <NavItem title='Liquidity Requests' image={liquidityIcon} link={Routes.Liquidity.path} />
                    )}
                    {permission?.accounts?.currencies?.permission?.visible[role] && (
                      <NavItem title='Currencies' image={CurrencyIcon} link={Routes.Currencies.path} />
                    )}
                  </>
                </CollapsableNavItem>
              )}

              {permission?.transactions?.permission?.visible[role] && (
                <NavItem title='Transactions' link={Routes.ReportTransactions.path} image={TransactionsIcon} />
              )}

              <Divider />
              {permission?.support?.permission?.visible[role] && (
                <CollapsableNavItem eventKey='support/' title='Support' image={SupportIcon}>
                  {permission?.support?.platformSupport?.permission?.visible[role] && (
                    <NavItem title='Platform Support' image={supportChatIcon} link={Routes.Support.path} subTitle={'Your queries with STB team'} />
                  )}
                  {permission?.support?.customerSupport?.permission?.visible[role] && (
                    <NavItem
                      title='Customer Support'
                      image={supportChatIcon}
                      link={Routes.UserChatGeneral.path}
                      subTitle={"Customers' queries with your team"}
                    />
                  )}
                </CollapsableNavItem>
              )}
              {permission?.profile?.permission?.visible[role] && <NavItem title='Profile' image={ProfileIcon} link={Routes.Profile.path} />}
            </Nav>
          </div>
        </Scrollbar>
      </CSSTransition>
    </>
  );
  return sidebarContent;
};

function mapStateToProps({ kybReducer, authReducer }) {
  const { status } = kybReducer;
  const { userRole, isSingleWallet } = authReducer;
  return {
    data: status.data,
    userRole,
    isSingleWallet,
  };
}

const actionCreators = {
  getStatus,
};

export default connect(mapStateToProps, actionCreators)(Sidebar);

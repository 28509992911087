import PropTypes from 'prop-types';
import React, { useState, useMemo, useEffect } from 'react';
// form
import { useTheme } from '@mui/material/styles';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Card, Stack, Divider, Typography } from '@mui/material';

import PaymentMethods from './PaymentMethods';
import PaymentMethodDetails from './PaymentMethodDetails';
import PaymentAccounts from './PaymentAccounts';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import usePageTitle from '../../../hooks/usePageTitle';
import { merchantApi } from '../../../redux/api/apiConfig';
import { FINANCIAL_OPTIONS } from '../../../ApiUrl';
import { toast } from 'react-toastify';

// ----------------------------------------------------------------------

FinancialAccounts.propTypes = {
  currentInvoice: PropTypes.object,
};

export default function FinancialAccounts() {
  const [financialAccountOptions, setFinancialAccountOptions] = useState([]);
  const [accountOptions, setAccountOptions] = useState([]);
  const [accountDetails, setAccountDetails] = useState();

  const {
    user: { finance_account },
  } = useSelector((state) => state.profileReducer);

  const getFinancialInstruments = async () => {
    try {
      const response = await merchantApi.get(FINANCIAL_OPTIONS);
      setFinancialAccountOptions(response.data.data);
      console.log(response);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      console.log(error);
    }
  };

  usePageTitle('MTO Financial Accounts', 'View your financial accounts');

  console.log(finance_account);

  const initialValues = useMemo(() => {
    if (finance_account.length === 0) {
      return {
        payment_method: 'bank',
        account: finance_account[0]?.id.toString(),
      };
    } else {
      return {
        payment_method: finance_account[0]?.type,
        account: finance_account[0]?.id.toString(),
      };
    }
  }, [finance_account]);

  const formik = useFormik({
    initialValues,
  });

  const { values, setFieldValue } = formik;

  useEffect(() => {
    const accountOptions = finance_account?.filter((method) => method.type == values.payment_method);
    setAccountOptions(accountOptions);
    setFieldValue('account', accountOptions[0]?.id);
  }, [values.payment_method, setFieldValue, finance_account]);

  useEffect(() => {
    const accountDetails = finance_account?.find((method) => method.id == values.account);
    setAccountDetails(accountDetails);
  }, [values.account, finance_account]);

  useEffect(() => {
    getFinancialInstruments();
  }, []);

  return (
    <Box sx={{ padding: '0 24px 24px' }}>
      <Card sx={{ p: 3 }}>
        <PaymentMethods options={financialAccountOptions} formik={formik} />
        <Divider variant='middle' sx={{ borderStyle: 'dashed', my: 3 }} />
        <PaymentAccounts options={accountOptions} accountDetails={accountDetails} name={'account'} formik={formik} />
        <Divider variant='middle' sx={{ borderStyle: 'dashed', my: 3 }} />
        {accountDetails ? <PaymentMethodDetails info={accountDetails} /> : ''}
      </Card>
    </Box>
  );
}

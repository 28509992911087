import React from 'react';
import { format } from 'date-fns';
import { startCase, toLower } from 'lodash';
import { Box, Stack, Typography, LinearProgress, Avatar, Tooltip, IconButton } from '@mui/material';
import { formatWithoutTimezone, rowsPerPageOptions, transactionStatusColors } from '../../../../../utils/common';

import { DataGrid } from '@mui/x-data-grid';
import { formatCurrency, tableCellStyle, capital } from '../../../../../utils/common';
import createAvatar from '../../../../../utils/createAvatar';
import { Label, ButtonGhost } from '../../../../../components';
import LinkRoundedIcon from '@mui/icons-material/LinkRounded';
import KeyRoundedIcon from '@mui/icons-material/KeyRounded';
import { useSelector } from 'react-redux';
import { Routes } from '../../../../../routes';
import { useHistory } from 'react-router-dom';
export default function Transactiontable({
  translist,
  fetchTranslistLoading,
  viewDetails,
  selectMto,
  deleteRemittance,
  updateStatus,
  signTransaction,
  customerCreated = false,
  handleChangePage,
  handleChangeRowsPerPage,
  filters,
}) {
  const role = useSelector((state) => state.profileReducer?.user?.role?.key);
  const permission = useSelector((state) => state.profileReducer?.permission);

  const history = useHistory();
  const createDataRow = (transaction) => {
    const type = transaction.type;
    return {
      id: transaction.reference_number,
      type: capital(transaction?.type) || 'P2P',
      submissionDate: formatWithoutTimezone(transaction.created_date, 'dd MMM yyyy HH:mm a'),
      senderMto: (() => startCase(toLower(transaction?.sender?.mto?.name)))(),
      sender: (() => {
        const senderType = type.split('2')[0];
        if (senderType === 'p') {
          return startCase(toLower(`${transaction.sender.first_name} ${transaction.sender.last_name}`));
        } else {
          return startCase(toLower(transaction.sender.business_name));
        }
      })(),
      recipientMto: (() => startCase(toLower(transaction?.receipt_mto?.name)))(),
      receiver: (() => {
        const receiverType = type.split('2')[1];
        if (receiverType === 'p') {
          return startCase(toLower(`${transaction.receiver.first_name} ${transaction.receiver.last_name}`));
        } else {
          return startCase(toLower(transaction.receiver.business_name));
        }
      })(),

      sentValue: (() => {
        return formatCurrency(
          transaction.send_amount,
          // Number(transaction.receiver_commission),
          transaction.send_currency?.decimal_place,
          transaction.send_currency?.symbol
        );
      })(),
      receivedValue: (() => {
        return formatCurrency(transaction.receive_amount, transaction.receive_currency?.decimal_place, transaction.receive_currency?.symbol);
      })(),
      // currency: transaction.currency.code,
      status: transaction?.current_status,
      updateStatus: transaction,
      details: transaction,
    };
  };

  const rows = translist?.results?.length > 0 ? translist.results.map((transaction) => createDataRow(transaction)) : [];

  const updateStatusAllowed = customerCreated
    ? permission?.remittances?.outward?.pendingremittance?.permission?.updateAllStatus[role]
    : permission?.remittances?.outward?.allremittance?.permission?.updateAllStatus[role];

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: 'header',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Tooltip placement='bottom' sx={{ cursor: 'default' }} title={params.formattedValue}>
            <Typography variant='body2' style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      minWidth: 60,
      // maxWidth: 150,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Label variant={'ghost'} color={'pending'} sx={{ textTransform: 'capitalize' }}>
            {params.formattedValue}
          </Label>
        );
      },
    },
    {
      field: 'submissionDate',
      headerName: 'Date',
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: 'senderMto',
      headerName: 'Sender MTO',
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: 'ellipsis',
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Stack direction={'row'} sx={{ minWidth: 0, alignItems: 'center' }}>
            <Avatar alt={params.formattedValue} sx={{ mr: 1, bgcolor: createAvatar(params.formattedValue).color }}>
              {createAvatar(params.formattedValue).name}
            </Avatar>
            <Typography
              variant='body2'
              sx={{
                color: 'black',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: '500',
              }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'sender',
      headerName: 'Sender',
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: 'ellipsis',
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Stack direction={'row'} sx={{ minWidth: 0, alignItems: 'center' }}>
            <Avatar alt={params.formattedValue} sx={{ mr: 1, bgcolor: createAvatar(params.formattedValue).color }}>
              {createAvatar(params.formattedValue).name}
            </Avatar>
            <Typography
              variant='body2'
              sx={{
                color: 'black',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: '500',
              }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'recipientMto',
      headerName: 'Recipient MTO',
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: 'ellipsis',
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Stack direction={'row'} sx={{ minWidth: 0, alignItems: 'center' }}>
            <Avatar alt={params.formattedValue} sx={{ mr: 1, bgcolor: createAvatar(params.formattedValue).color }}>
              {createAvatar(params.formattedValue).name}
            </Avatar>
            <Typography
              variant='body2'
              sx={{
                color: 'black',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: '500',
              }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },
    {
      field: 'receiver',
      headerName: 'End Beneficiary',
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Stack direction={'row'} sx={{ minWidth: 0, alignItems: 'center' }}>
            <Avatar alt={params.formattedValue} sx={{ mr: 1, bgcolor: createAvatar(params.formattedValue).color }}>
              {createAvatar(params.formattedValue).name}
            </Avatar>
            <Typography
              variant='body2'
              sx={{
                color: 'black',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontWeight: '500',
              }}
            >
              {params.formattedValue}
            </Typography>
          </Stack>
        );
      },
    },

    {
      field: 'sentValue',
      headerName: 'Sent Value',
      flex: 1,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },

    // {
    //   field: 'sentValueInCurrency',
    //   headerName: 'Sent Value in Currency',
    //   flex: 1,
    //   minWidth: 180,
    //   // maxWidth: 200,
    //   headerClassName: 'header',
    // },
    {
      field: 'receivedValue',
      headerName: 'Recv Value',
      flex: 1.5,
      minWidth: 150,
      // maxWidth: 200,
      headerClassName: 'header',
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },

    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      flex: 1.5,
      minWidth: 250,
      headerClassName: 'header',
      renderCell: (params) => {
        let { value, sender_name } = params.formattedValue;
        switch (params.formattedValue.value) {
          case 'pending':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {sender_name}
              </Label>
            );
          case 'processed':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {sender_name}
              </Label>
            );

          case 'reject_request':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {sender_name}
              </Label>
            );
          case 'expired':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {sender_name}
              </Label>
            );
          case 'failed':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {sender_name}
              </Label>
            );
          case 'payout_done':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {sender_name}
              </Label>
            );

          case 'redeem_complete':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {sender_name}
              </Label>
            );

          case 'reject_complete':
            return (
              <Label variant={'ghost'} color={transactionStatusColors[value]} sx={{ textTransform: 'capitalize' }}>
                {sender_name}
              </Label>
            );
          default:
            return (
              <Label variant={'ghost'} color={'application submitted'} sx={{ textTransform: 'capitalize' }}>
                {sender_name || '--'}
              </Label>
            );
        }
      },
    },

    {
      field: 'updateStatus',
      headerName: 'Update Status',
      sortable: false,
      flex: 1.5,
      headerClassName: 'header',
      minWidth: 160,
      renderCell: (params) => {
        return (
          <ButtonGhost
            appearance='ghost'
            size='xs'
            disabled={params?.row?.details?.current_status?.sender_can_change_to.length === 0 || !updateStatusAllowed}
            onClick={(e) => {
              e.stopPropagation();
              updateStatus(params.formattedValue);
            }}
          >
            Update Status
          </ButtonGhost>
        );
      },
    },

    {
      field: 'actions',
      headerName: 'Actions',
      sortable: false,
      // align: 'left',
      flex: 1,
      minWidth: 140,
      // maxWidth: 140,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Stack direction='row' spacing={2}>
            {customerCreated && (
              <Stack>
                <Tooltip placement='bottom' title='Sign Transaction'>
                  <span onClick={(e) => e.stopPropagation()}>
                    <IconButton
                      disabled={
                        params?.row?.status?.value !== 'approved_remittance' ||
                        !permission?.remittances?.outward?.pendingremittance?.permission?.updatemtoaccount[role]
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        signTransaction(params.row.details);
                      }}
                    >
                      <KeyRoundedIcon />
                    </IconButton>
                  </span>
                </Tooltip>
              </Stack>
            )}
            <Stack>
              <Tooltip placement='bottom' title='View in Blockchain'>
                <span>
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      window.open(params?.row?.details?.transaction?.blockchain_url, '_blank');
                    }}
                  >
                    <LinkRoundedIcon />
                  </IconButton>
                </span>
              </Tooltip>
            </Stack>
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          // height: 825,
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
          borderRadius: '8px',
          width: '100%',
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: '#667085',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
            },
          },
          '& .header': {
            backgroundColor: '#F4F4F4',
            '& div': {
              '& div': {
                '& div': {
                  '& div': {
                    fontSize: '14px',
                    fontWeight: 600,
                    fontFamily: 'Inter, sans-serif',
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            borderRadius: '8px',
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
            width: '100%',
            cursor: 'pointer',
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          onRowClick={(row) => {
            console.log(row);
            const id = row?.row?.details?.id;
            if (row?.row?.status?.value.toLowerCase() === 'draft') {
              history.push({
                pathname: `${Routes.AddOutwardRemittance.path}/${id}/`,
                // state: {
                //   id: row?.row?.details?.id,
                // },
              });
            } else {
              viewDetails(id);
            }
          }} // density={'compact'}
          rows={rows}
          columns={columns}
          rowHeight={50}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode='server'
          rowsPerPageOptions={rowsPerPageOptions}
          page={filters.currentPage}
          rowCount={translist.count}
          pageSize={filters.pageSize}
          onPageChange={(newPage) => handleChangePage(newPage)}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          loading={fetchTranslistLoading}
        />
      </Box>
    </>
  );
}

import React from 'react';
import { Page, Document, StyleSheet, View, PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { Button } from 'rsuite';
import Iconify from '../Iconify';
import RemittanceSection from './RemittanceSection';
import SenderSection from './SenderSection';
import RecipientSection from './RecipientSection';

const PreviewPDF = ({ coins, transaction, isInward }) => {
  const styles = StyleSheet.create({
    page: {
      // flexDirection: 'column',
      marginBottom: '24px',
      // backgroundColor: '#E4E4E4',
      padding: '8px',
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
  });
  const MyDocument = () => (
    <Document>
      <Page size='A4' style={styles.page}>
        <RemittanceSection coins={coins} transaction={transaction} isInward={isInward} />
        <SenderSection type={transaction.type} sender={transaction.sender} />
        <RecipientSection type={transaction.type} receiver={transaction.receiver} receiver_account={transaction.receiver_account} />
      </Page>
    </Document>
  );

  return (
    // <PDFViewer>
    //   <MyDocument />
    // </PDFViewer>
    <PDFDownloadLink document={<MyDocument />} fileName={`${transaction.reference_number}.pdf`}>
      <Button
        // color="error"
        className='btn load text-white'
      >
        <Iconify sx={{ mr: 1 }} icon={'eva:download-outline'} />
        Download
      </Button>
    </PDFDownloadLink>
  );
};

export default PreviewPDF;

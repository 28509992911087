import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { capitalize } from 'lodash';
import { Box, Typography, LinearProgress, Tooltip, Link } from '@mui/material';
import { rowsPerPageOptions } from '../../../../../utils/common';

import { DataGrid } from '@mui/x-data-grid';
import { formatCurrency, tableCellStyle } from '../../../../../utils/common';
import { Label } from '../../../../../components';
import { Routes } from '../../../../../routes';
import { useHistory } from 'react-router-dom';
export default function Transactiontable({
  translist,
  paginateTable,
  setpaginateTable,
  fetchTranslistLoading,
  viewDetails,

  signTransaction,
  customerCreated = false,
}) {
  const history = useHistory();

  const handleChangePage = (newPage) => {
    setpaginateTable({ ...paginateTable, currentPage: newPage });
  };

  const handleChangeRowsPerPage = (newSize) => {
    setpaginateTable({
      ...paginateTable,
      currentPage: 0,
      pageSize: newSize,
    });
  };

  const createDataRow = (transaction) => {
    const type = transaction.type;
    return {
      id: transaction?.id,
      type: capitalize(transaction.transaction_type),
      submissionDate: format(new Date(transaction.created_date), 'dd MMM yyyy HH:mm a'),
      amount: (() => {
        return formatCurrency(
          transaction.amount,
          transaction?.currency?.decimal_place,
          transaction?.currency?.symbol,
          transaction?.currency?.symbol_prefix
        );
      })(),
      remarks: transaction?.remarks || '--',
      relatedEntry: {
        related_to: transaction?.related_transaction,
        type: transaction?.transaction_mode,
      },
      balance: (() => {
        return formatCurrency(
          transaction?.balance_post_transaction,
          transaction?.currency?.decimal_place,
          transaction?.currency?.symbol,
          transaction?.currency?.symbol_prefix
        );
      })(),
    };
  };

  const rows = translist?.results?.length > 0 ? translist.results.map((transaction) => createDataRow(transaction)) : [];

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      flex: 1,
      minWidth: 120,
      // maxWidth: 200,
      headerClassName: 'header',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        return (
          <Tooltip placement='bottom' sx={{ cursor: 'default' }} title={params.formattedValue}>
            <Typography variant='body2' style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'type',
      headerName: 'Type',
      flex: 1,
      minWidth: 60,
      // maxWidth: 150,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Label variant={'ghost'} color={'pending'} sx={{ textTransform: 'capitalize' }}>
            {params.formattedValue}
          </Label>
        );
      },
    },
    {
      field: 'submissionDate',
      headerName: 'Date',
      flex: 1,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: 'ellipsis',
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
    {
      field: 'remarks',
      headerName: 'Remarks',
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: 'ellipsis',
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Tooltip
            placement='bottom'
            // sx={{ cursor: 'default' }}
            title={params.formattedValue}
          >
            <Typography variant='body2' style={tableCellStyle}>
              {params.formattedValue}
            </Typography>
          </Tooltip>
        );
      },
    },
    {
      field: 'relatedEntry',
      headerName: 'Related Entry',
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      textOverflow: 'ellipsis',
      headerClassName: 'header',
      renderCell: (params) => {
        console.log(params);
        const { related_to, type } = params.formattedValue;

        const { ref_no, id } = related_to;
        const linkClickHandler = () => {
          if (type === 'remittance') {
            history.push(Routes.OutwardRemittance.path + `/details/${id}`);
          } else if (type === 'deposit') {
            history.push(Routes.Deposits.path + `/${id}`);
          }
        };
        return (
          <Tooltip
            placement='bottom'
            // sx={{ cursor: 'default' }}
            title={ref_no}
          >
            <Link component='span' variant='subtitle2' color='text.primary' sx={{ cursor: 'pointer', fontWeight: '500' }} onClick={linkClickHandler}>
              {ref_no}
            </Link>
          </Tooltip>
        );
      },
    },
    {
      field: 'balance',
      headerName: 'Balance Post Transaction',
      flex: 1.5,
      minWidth: 200,
      // maxWidth: 200,
      headerClassName: 'header',
      renderCell: (params) => {
        return (
          <Typography variant='body2' style={tableCellStyle}>
            {params.formattedValue}
          </Typography>
        );
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          // height: 825,
          boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
          borderRadius: '8px',
          width: '100%',
          '& .MuiDataGrid-virtualScrollerRenderZone': {
            '& .MuiDataGrid-row': {
              // backgroundColor: 'rgba(235, 235, 235, .7)',
              fontWeight: 500,
              color: '#667085',
              fontFamily: 'Inter, sans-serif',
              fontSize: '14px',
            },
          },
          '& .header': {
            backgroundColor: '#F4F4F4',
            '& div': {
              '& div': {
                '& div': {
                  '& div': {
                    fontSize: '14px',
                    fontWeight: 600,
                    fontFamily: 'Inter, sans-serif',
                  },
                },
              },
            },
          },
        }}
      >
        <DataGrid
          autoHeight
          sx={{
            borderRadius: '8px',
            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 0px 8px 0px',
            width: '100%',
            cursor: 'pointer',
          }}
          components={{
            LoadingOverlay: LinearProgress,
          }}
          // onRowClick={(row) => {
          //   viewDetails(row.row.details.id);
          // }} // density={'compact'}
          rows={rows}
          columns={columns}
          rowHeight={50}
          disableColumnFilter={true}
          disableColumnMenu={true}
          pagination
          paginationMode='server'
          rowsPerPageOptions={rowsPerPageOptions}
          page={paginateTable.currentPage}
          rowCount={translist.count}
          pageSize={paginateTable.pageSize}
          onPageChange={(newPage) => handleChangePage(newPage)}
          onPageSizeChange={(newSize) => handleChangeRowsPerPage(newSize)}
          loading={fetchTranslistLoading}
        />
      </Box>
    </>
  );
}

import React, { useState } from 'react';
import { Stack, Link } from '@mui/material';
import Image from '../image/Image';
import { ButtonSecondary, Iconify } from '..';
import { merchantApi } from '../../redux/api/apiConfig';
import { SENDER_DOCUMENT } from '../../ApiUrl';
import { getThumbnail } from '../../utils/common';
import { toast } from 'react-toastify';

const DocumentCard = ({ isSelected = false, document, onDocumentSelect = () => {}, onDocumentDelete = () => {}, canDelete = false }) => {
  const [deleteLoading, setDeleteLoading] = useState(false);

  const deleteClickHandler = async () => {
    setDeleteLoading(true);
    try {
      const response = await merchantApi.delete(`${SENDER_DOCUMENT}${document.id}/`);
      onDocumentDelete(document.id);
      setDeleteLoading(false);
    } catch (err) {
      toast.error(err?.data?.data?.message || 'Something went wrong');
      console.log(err);
    }
  };

  return (
    <Stack spacing={1}>
      <Image
        key={document.id}
        alt='file preview'
        src={getThumbnail(document.document)}
        title={`${document?.name ?? ''} (${document?.document_type?.name ?? ''})`}
        isSelected={isSelected}
        onClick={() => onDocumentSelect(document.id)}
        sx={{
          borderRadius: 1,
          width: '200px',
          height: '150px',
          cursor: 'pointer',
          border: '1px solid #F0F0F0',
        }}
      />
      {/* <Stack>
        <ButtonPrimary onClick={() => onDocumentSelect(document.id)}>
          Select
        </ButtonPrimary>
      </Stack> */}
      <Stack direction={'row'} spacing={0.5}>
        <Link href={document.document} target={'_blank'} rel='noopener noreferef' flex={1}>
          <ButtonSecondary style={{ width: '100%' }}>Preview</ButtonSecondary>
        </Link>
        {canDelete && (
          <ButtonSecondary onClick={deleteClickHandler} loading={deleteLoading}>
            <Iconify icon={'eva:trash-2-outline'} />
          </ButtonSecondary>
        )}
      </Stack>
    </Stack>
  );
};

export default DocumentCard;

export const remittanceInitialState = {
  clientInfo: {
    type: 'p2p',
    purpose: '',
    other_purpose: '',
    target_country: '',
    target_country_id: '',
    sender: null,
    checkbox: false,
    sender_document: [],
  },
  recipientInfo: {
    recipient: null,
    financeAccount: null,
  },
  transferDetails: {
    senderAccount: null,
    send_currency: '',
    send_amount: '',
    receive_amount: '',
    receive_currency: '',
    recipient_mto: null,
    rate: 0,
    fee: { inward_fee: 0, outward_fee: 0 },
    deposit_type: '',
    other_deposit: '',
    currency: { send: '', receive: '' },
  },
  formState: {
    openConfirmDiscard: false,
  },
  remittanceDispatch: () => {},
};

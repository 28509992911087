import * as API from '../../ApiUrl';
import {
  GET_CONFIG,
  SET_ACCOUNT_TYPES,
  SET_CONFIG,
  SET_CURRENCY,
  SET_DEPOSIT_TYPES,
  SET_REMITTANCE_TYPES,
  SET_PURPOSE_TYPES,
  SET_KYC_DOC_TYPES,
  SET_REMITTANCE_STATUSES,
  SET_REDEEM_STATUSES,
  TOGGLE_SIDEBAR,
  SHOW_SIDEBAR,
  SET_PAGE_TITLE,
} from '../actionTypes';
import { merchantApi } from '../api/apiConfig';

const getConfigData = () => async (dispatch) => {
  dispatch({ type: GET_CONFIG });

  try {
    const res = await merchantApi.get(`${API.GLOBAL_CONFIG}`);
    dispatch({
      type: SET_CONFIG,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err, 'errr');
    // dispatch({
    //   type: GET_PROFILE_FAIL,
    // });
  }
};

const getCurrencyTypes = () => async (dispatch) => {
  try {
    const res = await merchantApi.get(`${API.CURRENCY_TYPES}`);
    dispatch({
      type: SET_CURRENCY,
      payload: res.data,
    });
  } catch (err) {
    console.log(err, 'errr');
  }
};
const getDepositTypes = () => async (dispatch) => {
  try {
    const res = await merchantApi.get(`${API.DEPOSIT_TYPES}`);
    dispatch({
      type: SET_DEPOSIT_TYPES,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err, 'errr');
  }
};
const getPurposeTypes = () => async (dispatch) => {
  try {
    const res = await merchantApi.get(`${API.REMITTANCE_PURPOSE}`);
    dispatch({
      type: SET_PURPOSE_TYPES,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err, 'errr');
  }
};
const getRemittanceTypes = () => async (dispatch) => {
  try {
    const res = await merchantApi.get(`${API.REMITTANCE_TYPES}`);
    dispatch({
      type: SET_REMITTANCE_TYPES,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err, 'errr');
  }
};

const getKycDocTypes = () => async (dispatch) => {
  try {
    const res = await merchantApi.get(`${API.GET_DOC_TYPES}`);
    dispatch({
      type: SET_KYC_DOC_TYPES,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err, 'errr');
  }
};

const getAccountTypes = () => async (dispatch) => {
  try {
    const res = await merchantApi.get(`${API.MTO_ACCOUNT_TYPES}`);
    dispatch({
      type: SET_ACCOUNT_TYPES,
      payload: res.data.data,
    });
  } catch (err) {
    console.log(err, 'errr');
  }
};

const getRemittanceStatuses = () => async (dispatch) => {
  try {
    const res = await merchantApi.get(`${API.REMITTANCE_STATUSES}`);

    dispatch({
      type: SET_REMITTANCE_STATUSES,
      payload: res.data,
    });
  } catch (err) {
    console.log(err, 'errr');
  }
};
const getRedeemStatuses = () => async (dispatch) => {
  try {
    const res = await merchantApi.get(`${API.REDEEM_STATUSES}`);
    dispatch({
      type: SET_REDEEM_STATUSES,
      payload: res.data.results,
    });
  } catch (err) {
    console.log(err, 'errr');
  }
};

const toggleSidebar = () => (dispatch) => {
  dispatch({
    type: TOGGLE_SIDEBAR,
  });
};

const showSidebar = () => (dispatch) => {
  dispatch({
    type: SHOW_SIDEBAR,
  });
};
const setPageTitle = (data) => (dispatch) => {
  dispatch({
    type: SET_PAGE_TITLE,
    payload: data,
  });
};

export {
  getConfigData,
  getCurrencyTypes,
  getDepositTypes,
  getRemittanceTypes,
  getAccountTypes,
  getPurposeTypes,
  getKycDocTypes,
  getRemittanceStatuses,
  getRedeemStatuses,
  toggleSidebar,
  showSidebar,
  setPageTitle,
};

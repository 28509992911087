import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button } from 'rsuite';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { setSingleWallet } from '../../redux/actions/auth';

import { merchantApi } from '../../redux/api/apiConfig';
import * as API from '../../ApiUrl';
import { create_account } from '../../utils/xrpl_functions';
import user from '../../assets/img/icons/user.svg';
import store from '../../assets/img/icons/store.svg';
import { toast } from 'react-toastify';

const WalletConfiguration = (props) => {
  const { open, handleClose, selectedValue, setSingleWallet, setSelectedWalletType } = props;
  const [loading, setLoading] = useState(false);

  const handleCallBack = (accountFormXrp) => {
    localStorage.removeItem('accountDetail');
    localStorage.setItem('accountDetail', JSON.stringify(accountFormXrp));
    setLoading(false);
  };

  const submitWalletConfig = async (walletType) => {
    setSelectedWalletType(walletType);
    handleCloseModal();
    selectedValue(walletType);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        await create_account(handleCallBack);
      } catch (err) {
        toast.error(err?.data?.data?.message || 'Account creation failed');
        // toast.error('Account creation failed.');
        setLoading(false);
      }
    })();
  }, []);

  const handleCloseModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    handleClose();
  };

  return (
    <Dialog
      open={open}
      disableEscapeKeyDown={true}
      onClose={handleCloseModal}
      className='wallet-configure'
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
          width: '560px',
          // minHeight: '731px',
          maxHeight: '95vh',
        },
      }}
    >
      <DialogTitle className='text-center'>
        <p className='dialog-title'>Welcome to Spend The Bits!</p>
      </DialogTitle>

      <hr className='hr' />

      <DialogContent className='merchant-wallet'>
        <p className='instructions'>
          <div className='d-flex space-between mb-4'>
            <Button className='primary_color text-white'>
              <img src={user} alt='store' />
            </Button>
            <Button disabled={loading} loading={loading} onClick={() => submitWalletConfig('merchant')} className='primary_color text-white fw-bold'>
              Next
            </Button>
          </div>
          <div className='instruction flex-row align-start'>
            {/* <span className="circle">1</span> */}
            <span className='inst-content'>
              <strong> Single Wallet:</strong>
              <br />
              In this model, merchant has a single wallet to receive user funds, irrespective of how many stores you create. All funds lie with the
              merchant account. <br />
              This model is generally suited when merchant wants to controll all funds and store has no access to the receive funds.
            </span>
          </div>
          <br />
        </p>
      </DialogContent>
      <hr className='hr' />
      {/* <p className="text-center p-4" style={{ color: 'red' }}>
        Please select the mode of operation to continue. Please not that this is
        a one time selection and cannot be modified later.
      </p> */}
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSingleWallet: () => dispatch(setSingleWallet()),
  };
};

export default connect(null, mapDispatchToProps)(WalletConfiguration);

// export default WalletConfiguration;

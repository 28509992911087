import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Tabs, Tab, Stack, Divider, MenuItem, Autocomplete, TextField, Card } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import 'rsuite/dist/styles/rsuite-default.css';

import * as API from '../../ApiUrl';
import Transactiontable from './ReportTransactions/Transactiontable';
import { merchantApi } from '../../redux/api/apiConfig';

import { format as Format } from 'date-fns';
import { formatDate } from '../../utils/common';
import { Box } from '@mui/material';
import usePageTitle from '../../hooks/usePageTitle';
import { reportTimePeriods } from '../reports/common';
import { FilterDropdown, Highlight, HighlightSkeleton } from '../reports/components';
import { StyledDatePicker } from '../../components/datePicker';
import { ButtonResetFilter } from '../../components';
import { CLEAR_TRANSACTION, UPDATE_TRANSACTION } from '../../redux/actionTypes';

function ReportTransactions() {
  const [accountOptions, setAccountOptions] = useState([]);

  const [translist, setTranslist] = useState([]);
  const [fetchTranslistLoading, setFetchTranslistLoading] = useState(false);
  const [highlights, setHighlights] = useState([]);
  const [highlightLoading, setHighlightLoading] = useState(false);
  const [resetData, setResetData] = useState(0);

  usePageTitle('Overview');

  const wallets = useSelector((state) => state?.walletsReducer?.wallets);
  const filters = useSelector((state) => state.filters.transactions);
  const location = useLocation();
  const dispatch = useDispatch();

  let filteredWallets = [];

  filteredWallets = wallets?.filter((wallet) => wallet.trust_line_set);
  const handleClearFilters = () => {
    dispatch({
      type: UPDATE_TRANSACTION,
      payload: {
        calenderValue: [new Date(reportTimePeriods[1].from), null],
        period: reportTimePeriods[1],
        currentPage: 0,
        pageSize: 7,
        search: '',
        mtoAccount: accountOptions[0],
        currency: filteredWallets[0],
        type: '',
      },
    });
  };

  const getTrans = useCallback(
    async (signal) => {
      setFetchTranslistLoading(true);
      const { currentPage, pageSize, search, currency, calenderValue, mtoAccount, type } = filters;
      const params = {
        created_date_after: formatDate(calenderValue[0], 'yyyy-MM-dd'),
        created_date_before: formatDate(calenderValue[1], 'yyyy-MM-dd'),
        limit: pageSize,
        offset: currentPage * pageSize,
        search: search,
        account: mtoAccount.id,
        coin: currency?.coin?.id,
        transaction_type: type,
      };
      try {
        const response = await merchantApi.get(`${API.TRANSFER_TRANSACTION}`, {
          params,
          signal,
        });
        setFetchTranslistLoading(false);
        if (response.data) {
          setTranslist(response.data);
        }
      } catch (error) {
        toast.error(error?.data?.data?.message);
        setFetchTranslistLoading(false);
      }
    },
    [filters]
  );
  const getHighlights = useCallback(
    async (signal) => {
      setHighlightLoading(true);
      try {
        const { mtoAccount, currency, calenderValue, pageSize, currentPage, amount_max, amount_min, type } = filters;

        const created_date_before = formatDate(calenderValue[1], 'yyyy-MM-dd');
        const created_date_after = formatDate(calenderValue[0], 'yyyy-MM-dd');

        const params = {
          account: mtoAccount?.id,
          coin: currency?.coin?.id,
          created_date_after,
          created_date_before,
          limit: pageSize,
          offset: pageSize * currentPage,
          amount_max: amount_max > 0 ? amount_max : '',
          amount_min: amount_min > 0 ? amount_min : '',
          transaction_type: type,
        };
        const response = await merchantApi.get(`${API.TRANSACTION_REPORT}highlight/`, {
          params,
          signal,
        });
        setHighlights(response.data.data);
        setHighlightLoading(false);
      } catch (error) {
        console.log(error);
        toast.error(error?.data?.data?.message || 'Something went wrong');
        setHighlightLoading(false);
      }
    },
    [filters]
  );

  const getAccounts = useCallback(async () => {
    try {
      const response = await merchantApi.get(API.MTO_ACCOUNTS);

      if (response.data) {
        const accOptions = response?.data?.results ?? [];
        setAccountOptions(accOptions);
        if (location?.state?.account) {
          const ac = accOptions.find((ac) => ac.id === Number(location.state.account));
          dispatch({ type: UPDATE_TRANSACTION, payload: { mtoAccount: ac } });
          // setTransactionReport((prev) => ({ ...prev, mtoAccount: ac }));
        } else {
          console.log(filters.mtoAccount, !filters.mtoAccount);
          if (!filters.mtoAccount) {
            dispatch({
              type: UPDATE_TRANSACTION,
              payload: { mtoAccount: accOptions[0] },
            });
          }
          // setTransactionReport((prev) => ({
          //   ...prev,
          //   mtoAccount: accOptions[0],
          // }));
        }
      }
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      console.log(error);
    }
  }, [location]);

  useEffect(() => {
    getAccounts();
  }, [getAccounts]);

  useEffect(() => {
    if (wallets) {
      const filteredWallets = wallets.filter((wallet) => wallet.trust_line_set);
      if (filteredWallets.length !== 0) {
        if (!filters.currency) {
          dispatch({
            type: UPDATE_TRANSACTION,
            payload: { currency: filteredWallets?.[0] },
          });
        }
      } else {
        toast.error('No wallet with trust line set found.');
        setHighlightLoading(false);
        setFetchTranslistLoading(false);
      }
    }
  }, [wallets, dispatch, filters.currency]);

  useEffect(() => {
    if (location?.state?.coin && location?.state?.account) {
      const filteredWallets = wallets?.filter((wallet) => wallet.trust_line_set);
      const coinId = location.state.coin;
      const accId = location.state.account;
      const account = accountOptions.find((account) => account.id == accId);
      const coin = filteredWallets.find((wallet) => wallet.coin.id == coinId);
      dispatch({
        type: UPDATE_TRANSACTION,
        payload: { currentPage: 0, mtoAccount: account, currency: coin },
      });
    }
    // else {
    //   if (wallets) {
    //     const filteredWallets = wallets.filter(
    //       (wallet) => wallet.trust_line_set
    //     );
    //     dispatch({
    //       type: UPDATE_TRANSACTION,
    //       payload: { currency: filteredWallets?.[0] },
    //     });
    //   }
    // }
  }, [location, accountOptions, wallets, dispatch]);

  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    let timer;
    if (filters.mtoAccount && filters.currency) {
      timer = setTimeout(() => {
        getTrans(signal);
        getHighlights(signal);
      }, 500);
    }
    return () => {
      controller.abort();
      clearTimeout(timer);
    };
  }, [
    filters.currentPage,
    filters.pageSize,
    filters.search,
    filters.coin,
    filters.calenderValue,
    filters.mtoAccount,
    filters.currency,
    resetData,
    getTrans,
    getHighlights,
  ]);

  return (
    <>
      <Box sx={{ padding: '0 24px 24px' }}>
        <Card sx={{ width: '100%' }}>
          <Stack direction='row' divider={<Divider orientation='vertical' flexItem sx={{ borderStyle: 'dashed' }} />} sx={{ py: 2 }}>
            {highlightLoading
              ? [...Array(1)].map((a) => <HighlightSkeleton />)
              : highlights.map((highlight, i) => {
                  return (
                    <Highlight
                      key={highlight.heading}
                      title={highlight.heading}
                      subTitle={highlight.sub_heading}
                      // total={translist.count}
                      percent={100}
                      value={highlight.data}
                      icon={highlight?.meta?.icon}
                      color={highlight?.meta?.color}
                    />
                  );
                })}
          </Stack>
        </Card>
        <Stack
          sx={{
            overflowX: { xs: 'auto' },
            overflowY: { xs: 'visible', md: 'unset' },
            padding: '5px 0',
          }}
          direction={'row'}
          justifyContent={'space-between'}
          gap={1}
          mt={2}
          mb={2}
          mr={2}
          width={'100%'}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              gap: 1,
            }}
            divider={<Divider orientation='vertical' flexItem sx={{ borderStyle: 'dashed' }} />}
          >
            <Stack sx={{ maxWidth: '150px', flex: 1 }}>
              <FilterDropdown
                name='timeperiod'
                type='text'
                label='Timeperiod'
                options={reportTimePeriods}
                onChange={(e) => {
                  const name = e.target.value;
                  const period = reportTimePeriods.find((report) => report.name === name);

                  let toValue;
                  if (filters.calenderValue[1]) {
                    toValue = new Date(period.from) > new Date(filters.calenderValue[1]) ? null : filters.calenderValue[1];
                  } else {
                    toValue = null;
                  }

                  dispatch({
                    type: UPDATE_TRANSACTION,
                    payload: {
                      calenderValue: [new Date(period.from), toValue],
                      period,
                    },
                  });
                  // setTransactionReport((prevState) => ({
                  //   ...prevState,
                  //   calenderValue: [new Date(period.from), toValue],
                  //   period,
                  // }));
                }}
                value={filters?.period?.name}
                size={'small'}
                render={(period, i) => (
                  <MenuItem key={period.name} value={period.name}>
                    {period.name}
                  </MenuItem>
                )}
              />
            </Stack>
            <Stack sx={{ flexDirection: 'row', gap: 1 }}>
              <Stack sx={{ width: '165px', flex: 1 }}>
                <StyledDatePicker
                  value={filters.calenderValue[0]}
                  disableFuture
                  format={'dd/MM/yyyy'}
                  onChange={(newValue) => {
                    dispatch({
                      type: UPDATE_TRANSACTION,
                      payload: {
                        calenderValue: [
                          new Date(Format(newValue, 'yyyy/MM/dd')),
                          newValue > filters.calenderValue[1] ? undefined : filters.calenderValue[1],
                        ],
                      },
                    });
                    // setTransactionReport((prevState) => ({
                    //   ...prevState,
                    //   calenderValue: [
                    //     new Date(Format(newValue, 'yyyy/MM/dd')),
                    //     newValue > filters.calenderValue[1]
                    //       ? undefined
                    //       : filters.calenderValue[1],
                    //   ],
                    // }));
                  }}
                />
              </Stack>

              <Stack sx={{ width: '165px', flex: 1 }}>
                <StyledDatePicker
                  value={filters.calenderValue[1]}
                  disableFuture
                  // minDate={filters.calenderValue[0] ?? undefined}
                  format={'dd/MM/yyyy'}
                  onChange={(newValue) => {
                    dispatch({
                      type: UPDATE_TRANSACTION,
                      payload: {
                        calenderValue: [
                          newValue < filters.calenderValue[0] ? undefined : filters.calenderValue[0],
                          new Date(Format(newValue, 'yyyy/MM/dd')),
                        ],
                      },
                    });
                    // setTransactionReport((prevState) => ({
                    //   ...prevState,
                    //   calenderValue: [
                    //     newValue < filters.calenderValue[0]
                    //       ? undefined
                    //       : filters.calenderValue[0],
                    //     new Date(Format(newValue, 'yyyy/MM/dd')),
                    //   ],
                    // }));
                  }}
                />
              </Stack>
            </Stack>
            <Stack sx={{ flexDirection: 'row', gap: 1, flex: 1 }}>
              <Stack sx={{ minWidth: '205px', flex: 1 }}>
                <Autocomplete
                  id='mtoAccount'
                  fullWidth
                  value={filters.mtoAccount}
                  onChange={(e, newValue) => {
                    dispatch({
                      type: UPDATE_TRANSACTION,
                      payload: {
                        mtoAccount: newValue ?? null,
                      },
                    });
                    // setTransactionReport((prevState) => ({
                    //   ...prevState,
                    //   mtoAccount: newValue ?? null,
                    // }));
                  }}
                  options={accountOptions}
                  getOptionLabel={(option) => `${option?.name}`}
                  // sx={{ flexGrow: 1, width: '100%' }}
                  renderInput={(params) => {
                    return <TextField placeholder='Select MTO account' {...params} size='small' />;
                  }}
                />
              </Stack>

              {wallets && (
                <Stack sx={{ minWidth: '205px', flex: 1 }}>
                  <Autocomplete
                    id='currency'
                    value={filters.currency}
                    onChange={(e, newValue) => {
                      dispatch({
                        type: UPDATE_TRANSACTION,
                        payload: {
                          currency: newValue ?? null,
                        },
                      });
                      // setTransactionReport((prevState) => ({
                      //   ...prevState,
                      // }));
                    }}
                    options={filteredWallets}
                    getOptionLabel={(option) => `${option?.coin?.name}`}
                    renderInput={(params) => {
                      return <TextField placeholder='Select Currency' {...params} size='small' />;
                    }}
                  />
                </Stack>
              )}
            </Stack>
              <Stack
            sx={{
              flexDirection: 'row',
              gap: 1,
            }}
          >
            <ButtonResetFilter onClick={handleClearFilters} />
          </Stack>
          </Stack>
        
        </Stack>

        <Transactiontable
          translist={translist}
          fetchTranslistLoading={fetchTranslistLoading}
          resetTransactions={setResetData}
          accountTitle={filters?.mtoAccount?.name}
        />
      </Box>
    </>
  );
}

export default ReportTransactions;

import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import { merchantApi } from '../../../../../redux/api/apiConfig';
import * as yup from 'yup';
import { useFormik } from 'formik';
import * as API from '../../../../../ApiUrl';
import { Button } from 'rsuite';
import { Autocomplete, DialogActions, DialogContent, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { TextFieldComponent, TextFieldSelect, CountrySelect, PhoneNumberInput, SplitInputField } from '../../../../../components';
import { toast } from 'react-toastify';

import { useEffect } from 'react';
// import { useSelector } from 'react-redux';

const validationSchema = yup.object({
  business_name: yup.string('Enter your business name').min(2, 'Please enter valid name').required('Business Name is required!'),

  first_name: yup.string('Enter your first name').min(2, 'Please enter valid name'),
  last_name: yup.string('Enter your last name').min(2, 'Please enter valid name'),
  // .required('Last Name is required!'),
  sender: yup.object().notRequired().nullable(),

  primary_designation: yup.string('Enter your primary designation').min(2, 'Please enter valid designation'),
  primary_contact_no: yup.string('Enter your primary phone number'),
  // .required('Phone Number is required'),
  secondary_contact_no: yup.string('Enter your secondary phone number'),

  country: yup.string('Select Country'),
  // .required('Country is required'),
  province: yup.string('Select Province'),
  // .required('Province is required'),
  city: yup.string('Enter City'),
  // .required('City is required'),
  address: yup.string('Enter address'),
  // .required('Address is required'),
});

const BusinessInfo = ({ onClose, handleNext, businessInfo, isEdit, loading, fromRemittance }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedProvince, setSelectedProvince] = useState('');
  const [render, setRender] = useState(0);
  const [countryList, setCountryList] = useState(JSON.parse(localStorage.getItem('country-list')) || []);

  const [provinceList, setProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [senderOptions, setSenderOptions] = useState([]);
  const countryCodes = countryList.reduce((acc, cur) => {
    acc.push(cur.code2);
    return acc;
  }, []);
  // let mtoCountry = [];
  // mtoCountry.push(
  //   useSelector((state) => state?.profileReducer?.user?.mto?.country?.code2)
  // );

  const userInfo = JSON.parse(localStorage.getItem('user-info'));

  const getProvince = async (country) => {
    try {
      const response = await merchantApi.get(`${API.PROVINCE_LIST}?country=${country}&limit=1000`);
      setProvinceList(response.data.results);
    } catch (err) {
      toast.error(err?.data?.data?.message || 'Something went wrong');
      console.log(err);
    }
  };
  const getCities = async (country) => {
    try {
      const response = await merchantApi.get(`${API.CITY_LIST}?country=${country}&region=${formik.values.province}&limit=1000`);
      setCityList(response.data.results);
    } catch (err) {
      toast.error(err?.data?.data?.message || 'Something went wrong');
      console.log(err);
    }
  };

  const getSenderList = async () => {
    try {
      const response = await merchantApi.get(API.SENDER);
      setSenderOptions(response.data);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      console.log(error);
    }
  };

  useEffect(() => {
    if (render !== 0) {
      getProvince(selectedCountry);
      setProvinceList([]);
      setCityList([]);
      formik.setFieldValue('province', '');
      formik.setFieldValue('city', '');
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (render !== 0) {
      getCities(selectedCountry);
      setCityList([]);
      formik.setFieldValue('city', '');
    }
  }, [selectedProvince]);

  useEffect(() => {
    getSenderList();
    if (businessInfo.country) {
      const code = countryList.find((country) => country.id === businessInfo.country).code2;
      // setSelectedCountry(businessInfo.country);
      formik.setFieldValue('country', code);
      getProvince(businessInfo.country);
      if (businessInfo.province) {
        formik.setFieldValue('province', businessInfo.province);
        getCities(businessInfo.country);
      }

      if (businessInfo.city) {
        formik.setFieldValue('city', businessInfo.city);
      }
    }
    return () => {
      setProvinceList([]);
      setCityList([]);
    };
  }, []);

  const [first_name, last_name] = businessInfo.primary_contact_name.split(' ');

  const formik = useFormik({
    initialValues: { ...businessInfo, first_name, last_name },
    validateOnBlur: true,
    validationSchema: validationSchema,

    // isInitialValid: isEdit,
    validateOnMount: true,
  });

  console.log(formik);

  const nextClickHandler = () => {
    // setBusinessInfo({ ...formik.values });
    let country;
    if (formik.values.country) {
      country = countryList.find((country) => country.code2 === formik.values.country).id;
      console.log(country);
    }
    let data = {
      ...formik.values,
      primary_contact_name: `${formik.values.first_name} ${formik.values.last_name}`,
    };

    console.log(formik);
    delete data.first_name;
    delete data.last_name;
    handleNext({
      ...data,
      country,
      status: 'draft',
      sender: formik?.values?.sender,
    });
  };

  return (
    <>
      <DialogContent sx={{ paddingTop: '20px !important', paddingBottom: '0 !important' }}>
        <Box
          sx={{
            display: 'grid',
            columnGap: '16px',
            rowGap: '12px',
            marginBottom: '12px',
            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
          }}
        >
          <TextFieldComponent formik={formik} name={'business_name'} type={'text'} label={'Business Name'} />
          <SplitInputField
            formik={formik}
            titleLeft='First Name'
            nameLeft='first_name'
            // placeholderLeft="First Name"
            typeLeft='text'
            basisLeft='50%'
            titleRight='Last Name'
            nameRight='last_name'
            // placeholderRight="Last Name"
            typeRight='text'
            basisRight='50%'
          />

          <Stack spacing={0} width={'100%'}>
            <Typography>Belongs To</Typography>
            <Autocomplete
              // disablePortal
              disabled={fromRemittance || isEdit}
              id='sender'
              value={formik.values.sender}
              isOptionEqualToValue={(option, value) => {
                console.log(option, value);
                return option.id === value.id;
              }}
              onChange={(e, newValue) => {
                formik.setFieldValue('sender', newValue);
                console.log(newValue);
              }}
              options={senderOptions}
              getOptionLabel={(option) =>
                option.sender_type === 'individual'
                  ? `${option?.first_name} ${option?.last_name} (${option?.xrpl_tag})`
                  : `${option?.business_name} (${option?.xrpl_tag})`
              }
              sx={{ flexGrow: 1, width: '100%' }}
              renderInput={(params) => {
                return <TextField {...params} />;
              }}
            />
          </Stack>

          <TextFieldComponent formik={formik} name={'primary_designation'} type={'text'} label={'Primary Contact Designation'} />

          <PhoneNumberInput formik={formik} label={'Primary Contact One'} name={'primary_contact_no'} setPhoneNumber={setPhoneNumber} />
          <PhoneNumberInput formik={formik} label={'Primary Contact Two'} name={'secondary_contact_no'} setPhoneNumber={setPhoneNumber} />
          <CountrySelect
            formik={formik}
            label={'Country'}
            name={'country'}
            options={countryCodes}
            // disabled={true}
            countryList={countryList}
            onChange={(id) => {
              setRender((prevState) => prevState + 1);
              setSelectedCountry(id);
            }}
          />
          <TextFieldSelect
            formik={formik}
            name={'province'}
            label={'Province'}
            options={provinceList}
            onChange={(event) => {
              setRender((prevState) => prevState + 1);
              setSelectedProvince(event.target.value);
            }}
            render={(province) => (
              <MenuItem value={province.id} key={province.id}>
                {province.name}
              </MenuItem>
            )}
          />
          <TextFieldSelect
            formik={formik}
            name={'city'}
            label={'City'}
            options={cityList}
            render={(city) => (
              <MenuItem value={city.id} key={city.id}>
                {city.name}
              </MenuItem>
            )}
          />
        </Box>
        <TextFieldComponent formik={formik} name={'address'} type={'text'} label={'Address'} multiline minRows={4} maxRows={4} />
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button className='btn white-btn action-button ' onClick={onClose} appearance='subtle'>
            Cancel
          </Button>
          <Button
            className={`btn action-button primary_color text-white`}
            appearance='primary'
            loading={loading}
            onClick={nextClickHandler}
            disabled={isEdit ? false : !formik.isValid}
          >
            Next
          </Button>
        </DialogActions>
      </DialogContent>
    </>
  );
};

export default BusinessInfo;

import React, { useState, useEffect } from 'react';
import { Form, InputGroup, Col, Button } from '@themesberg/react-bootstrap';
import { STORE_LIST } from '../../../ApiUrl';
import axios from 'axios';
import CounterTable from './CounterTable';
import { connect } from 'react-redux';
// import { getStorelist } from '../../../redux/actions/storeDetail';
import { useHistory } from 'react-router';
import { BiEdit } from 'react-icons/bi';
import _ from 'lodash';
import { ToastContainer, toast } from 'react-toastify';

import { Chip } from '@mui/material';

import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';

import AddCounter from './component/AddNewCounter';
import EditCounter from './component/EditCounter';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { COUNTER } from '../../../ApiUrl';
import add from '../../../assets/img/icons/add.svg';
import dropDown from '../../../assets/img/icons/dropDown.svg';
import greentick from '../../../assets/img/icons/greentick.svg';
import cross from '../../../assets/img/icons/cross.svg';
import back from '../../../assets/img/icons/back.svg';

const Counter = ({ location }) => {
  const params = useParams();

  const [editcounterdata, seteditcounterdata] = useState({});
  const [editcounter, seteditcounter] = useState(false);
  const [storeData, setstoreData] = useState({});
  const [tempStoreData, setTempStoreData] = useState({});
  const [loading, setloading] = useState(false);
  const [addcounter, setaddcounter] = useState(false);
  const [filterKey, setFilterKey] = useState('');

  const [paginateTable, setpaginateTable] = useState(
    location.state?.page || {}
  );

  const history = useHistory();

  useEffect(() => {
    getStoreList();
  }, []);

  useEffect(() => {
    // console.log(storeData);
    if (Object.keys(storeData).length > 0 && storeData['counter'].length > 0) {
      // console.log(storeData, '=====');
      handleStatusFilter(filterKey);
    }
  }, [storeData]);

  const onBackClick = () => {
    history.goBack();
  };

  const getStoreList = async () => {
    setloading(true);
    try {
      let token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(`${STORE_LIST}${params.id}/`, config);
      // console.log('store list show ', response);

      setstoreData(response.data);
      setTempStoreData(response.data);
    } catch (error) {
      console.log('trans list error ', error);
    }
    setloading(false);
  };

  const ColDetail = ({ name, value }) => (
    <div className="row mt-3">
      <div className="col-3 text-info font-weight-bold font-18">{name}</div>
      <div className="col-9 text-black font-18">{value}</div>
    </div>
  );
  const handleaddCounter = () => {
    setaddcounter(!addcounter);
    getStoreList();
  };
  const handleeditCounter = () => {
    seteditcounter(!editcounter);
    getStoreList();
  };
  const handlecounterediting = (p) => {
    seteditcounterdata(p);
    seteditcounter(true);
  };

  const handleStatusFilter = (key) => {
    switch (key) {
      case 'Select Status':
        setFilterKey('');
        setTempStoreData({
          ...storeData,
        });
        break;
      case 'Active':
        const activeCounters = _.filter(storeData.counter, ['active', true]);
        setFilterKey('Active');
        setTempStoreData({
          ...storeData,
          counter: activeCounters,
        });
        break;
      case 'Inactive':
        const inactiveCounters = _.filter(storeData.counter, ['active', false]);
        setFilterKey('Inactive');
        setTempStoreData({
          ...storeData,
          counter: inactiveCounters,
        });
        break;
      default:
        break;
    }
  };

  const handlePOSStatusChange = async (selectedData) => {
    console.log(selectedData);

    const data = {
      active: !selectedData.active,
    };
    let token = localStorage.getItem('token');
    setloading(true);
    try {
      // console.log("user list token", token );
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.patch(
        `${COUNTER}${selectedData.id}/`,
        data,
        config
      );
      console.log('trans list show ', response);
      toast.success('Counter successfully Updated');
      getStoreList();
    } catch (error) {
      toast.error(error.response.data.message);
      setloading(false);
      console.log('trans list error ', error.response.data);
    }
    setloading(false);
  };

  return (
    <>
      <div className="page-wrapper stb-page-wrapper stb-ph-8">
        <div className="user stb-h-100">
          <div className="users stb-h-100 bg-white p-4 radi">
            <span onClick={onBackClick} className="back mb-4">
              <img src={back} alt="back" />
              <span>Back</span>
            </span>
            <div className="page-header pb-4 space-between">
              <div>
                <h3 className="m-0 mb-2 page-title">POS List</h3>

                {storeData.status === 'trust_line_not_set' ? (
                  <Chip
                    style={{
                      fontSize: '13px',
                      fontWeight: '600',
                      backgroundColor: '#f8dada',
                      color: '#B42318',
                    }}
                    avatar={
                      <img
                        src={cross}
                        style={{ width: '13px', marginLeft: '10px' }}
                        alt="tick"
                      />
                    }
                    color="error"
                    label="Inactive"
                  />
                ) : (
                  <Chip
                    style={{
                      fontSize: '13px',
                      fontWeight: '600',
                      backgroundColor: '#ECFDF3',
                      color: '#027A48',
                    }}
                    avatar={
                      <img
                        src={greentick}
                        style={{ width: '13px', marginLeft: '10px' }}
                        alt="tick"
                      />
                    }
                    label="Active"
                    color="success"
                  />
                )}
              </div>

              <div className="tools gap-4">
                <div className="filter">
                  <Dropdown as={ButtonGroup} className="w-100">
                    <Dropdown.Toggle id="dropdown-basic">
                      {filterKey || 'Select Status'}
                      <img
                        src={dropDown}
                        style={{ marginLeft: '4px' }}
                        alt="drop-down"
                      />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="super-colors">
                      <Dropdown.Item
                        as="button"
                        value="Select Status"
                        onClick={() => {
                          handleStatusFilter('Select Status');
                        }}
                        key={'select_status'}
                      >
                        Select Status
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        value="Active"
                        onClick={() => {
                          handleStatusFilter('Active');
                        }}
                        key={'active'}
                      >
                        Active
                      </Dropdown.Item>
                      <Dropdown.Item
                        as="button"
                        value="Inactive"
                        onClick={() => {
                          handleStatusFilter('Inactive');
                        }}
                        key={'inactive'}
                      >
                        InActive
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className="filter">
                  <Button
                    onClick={() => setaddcounter(!addcounter)}
                    style={{ minHeight: '40px' }}
                    appearance="default"
                    className="btn load text-white d-flex flex-row gap-2 "
                  >
                    <span>Add New POS</span>
                    {/* <img src={add} alt="add" /> */}
                  </Button>
                </div>
              </div>
            </div>

            <CounterTable
              data={tempStoreData.counter || []}
              loading={loading}
              setpaginateTable={(p) => setpaginateTable}
              paginateTable={paginateTable}
              handleeditCounter={(p) => handlecounterediting(p)}
              handlePOSStatusChange={handlePOSStatusChange}
            />
          </div>
        </div>
      </div>

      {addcounter && (
        <AddCounter
          open={addcounter}
          handleaddCounter={() => handleaddCounter()}
          id={params.id}
          onClose={() => setaddcounter(!addcounter)}
        />
      )}
      {editcounter && (
        <EditCounter
          data={editcounterdata}
          open={editcounter}
          handleeditCounter={() => handleeditCounter()}
          id={editcounterdata.id}
          onClose={() => seteditcounter(!editcounter)}
        />
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    storelist: state.storeReducer.storelist,
  };
}

const actionCreators = {
  // getStorelist,
};

export default connect(mapStateToProps, actionCreators)(Counter);

import { useEffect } from 'react';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box } from '@material-ui/core';
import DropdownSearch from '../../../../../components/DropdownSearch';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Button } from 'rsuite';
import Iconify from '../../../../../components/Iconify';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { AddIndividualReceiverClient, AddBusinessReceiverClient } from '../../../../clients/receivers/components';
import { Stack, Typography, IconButton, Divider, TextField, Autocomplete } from '@mui/material';
import { PreviewItem, PreviewContainer } from '../../../../../components';
import { useRemittance } from '../../../../../context/remittance/remittanceContext';

const RecipientInfo = ({ handleBack, handleNext }) => {
  const { clientInfo, recipientInfo, transferDetails, remittanceDispatch } = useRemittance();
  const [yupObject, setYupObject] = useState({
    recipient: yup.object().required('Recipient is required').nullable(),
    financeAccount: yup.object().required('Recipient account is required').nullable(),
  });
  const [validationSchema, setValidationSchema] = useState(yup.object(yupObject));

  const [receiver, setReceiver] = useState(recipientInfo.recipient);
  const [selectedFinancialAccount, setSelectedFinancialAccount] = useState(recipientInfo.financeAccount);
  const [financialAccounts, setFinancialAccounts] = useState([]);
  const [showBusinessFinancialModal, setShowBusniessFinancialModal] = useState(false);
  const [showIndividualFinancialModal, setShowIndividualFinancialModal] = useState(false);

  const [showAddIndividualRecipient, setShowAddIndividualRecipinet] = useState(false);
  const [showAddBusinessRecipient, setShowAddBusinessRecipinet] = useState(false);

  const [refetch, setRefetch] = useState(0);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));
  const { walletsReducer } = useSelector((state) => state);
  const { coins = [] } = walletsReducer;

  useEffect(() => {
    console.log(recipientInfo, transferDetails);
    const { recipient, financeAccount } = recipientInfo;
    setReceiver(recipient);
    setSelectedFinancialAccount(financeAccount);
  }, []);

  useEffect(() => {
    if (receiver) {
      setFinancialAccounts(receiver.finance_account);
    }
  }, [receiver, selectedFinancialAccount]);

  const toggleShowAddIndividualRecipient = useCallback(() => {
    setShowAddIndividualRecipinet((prevState) => !prevState);
  }, []);

  const toggleShowAddBusinessRecipient = useCallback(() => {
    setShowAddBusinessRecipinet((prevState) => !prevState);
  }, []);

  const toggleIndividualModal = () => {
    setShowIndividualFinancialModal((prevState) => !prevState);
  };

  const toggleBusinessModal = () => {
    setShowBusniessFinancialModal((prevState) => !prevState);
  };

  const onReceiverChange = useCallback((value) => {
    formik.setFieldValue('recipient', value);
    setReceiver(value);
    const selectedCoinId = coins.find((coin) => coin.symbol === value.currency.code)?.id;
    // setTransferDetails((prevState) => ({
    //   ...prevState,
    //   receive_currency: selectedCoinId,
    //   currency: { ...prevState.currency, receive: value.currency.code },
    // }));

    console.log(transferDetails, value);

    remittanceDispatch({
      type: 'UPDATE_TRANSFER_DETAILS',
      payload: { receive_currency: selectedCoinId, currency: { ...transferDetails.currency, receive: value.currency.code } },
    });

    setSelectedFinancialAccount(null);
    formik.setFieldValue('financeAccount', null);
  }, []);

  const onAddIndAcc = () => {
    toggleIndividualModal();
    if (showIndividualFinancialModal) {
      remittanceDispatch({
        type: 'UPDATE_RECIPIENT_INFO',
        payload: { ...formik.values, recipient: null, financeAccount: null },
      });
      // setRecipientInfo((prevState) => ({
      //   ...prevState,
      //   recipient: null,
      //   financeAccount: null,
      // }));
      setReceiver(null);
      setSelectedFinancialAccount(null);
    }
    setRefetch((state) => state + 1);
  };

  const onAddBusAcc = () => {
    toggleBusinessModal();
    if (showBusinessFinancialModal) {
      remittanceDispatch({
        type: 'UPDATE_RECIPIENT_INFO',
        payload: { ...formik.values, recipient: null, financeAccount: null },
      });
      // setRecipientInfo((prevState) => ({
      //   ...prevState,
      //   recipient: null,
      //   financeAccount: null,
      // }));
      setReceiver(null);
      setSelectedFinancialAccount(null);
    }
    setRefetch((state) => state + 1);
  };

  const recipientType = clientInfo.type.split('2')[1];
  let content;
  if (receiver) {
    if (recipientType === 'p') {
      content = (
        <>
          <PreviewItem title={'First Name'} content={receiver?.first_name} />
          <PreviewItem title={'Last Name'} content={receiver?.last_name} />
        </>
      );
    } else if (recipientType === 'b') {
      content = (
        <>
          <PreviewItem title={'Business Name'} content={receiver?.business_name} />
          <PreviewItem title={'Primary Contact Name'} content={receiver?.primary_contact_name} />
        </>
      );
    }
  }

  let financialDetails = '';
  if (selectedFinancialAccount) {
    const type = selectedFinancialAccount.type;
    if (type === 'venmo') {
      financialDetails = (
        <>
          <PreviewItem title={'Venmo ID'} content={selectedFinancialAccount?.venmo_id} />
        </>
      );
    } else if (type === 'paypal') {
      financialDetails = (
        <>
          <PreviewItem title={'PayPal Email'} content={selectedFinancialAccount?.email} />
        </>
      );
    } else if (type === 'bank') {
      const { name, account_no, lei_code, branch, iban, swift_code, transit_number, aba_number, address, ifsc_code } = selectedFinancialAccount.bank;
      financialDetails = (
        <>
          <PreviewItem title={'Bank Name'} content={name} />
          <PreviewItem title={'Branch'} content={branch} /> <PreviewItem title={'Account Number'} content={account_no} />{' '}
          <PreviewItem title={'LEI Code'} content={lei_code} /> <PreviewItem title={'IBAN'} content={iban} />{' '}
          <PreviewItem title={'SWIFT BIC Code'} content={swift_code} />
          <PreviewItem title={'Transit Number'} content={transit_number} />
          <PreviewItem title={'ABA Routing Number'} content={aba_number} />
          <PreviewItem title={'IFSC Code'} content={ifsc_code} />
          <PreviewItem title={'Address'} content={address} />
        </>
      );
    }
  }

  const formik = useFormik({
    initialValues: { ...recipientInfo },
    validateOnBlur: true,
    validationSchema: validationSchema,

    // isInitialValid: isEdit,
    validateOnMount: true,
  });

  const nextClickHandler = () => {
    remittanceDispatch({
      type: 'UPDATE_RECIPIENT_INFO',
      payload: { ...formik.values },
    });
    // setRecipientInfo({ ...formik.values });
    handleNext();
  };

  const backClickHandler = () => {
    console.log(formik);
    remittanceDispatch({
      type: 'UPDATE_RECIPIENT_INFO',
      payload: { ...formik.values },
    });
    // setRecipientInfo({ ...formik.values });
    handleBack();
  };

  console.log(formik);
  return (
    <>
      <Box sx={{ padding: '24px ' }}>
        <Stack spacing={3} direction={'column'}>
          <Stack
            spacing={3}
            direction={{
              sm: 'row',
              xs: 'column',
            }}
            alignItems={'stretch'}
          >
            <Stack direction={'column'} spacing={4} sx={{ flex: 1 }}>
              <Stack
                spacing={2}
                direction={'row'}
                sx={{
                  alignItems: 'flex-end',
                }}
              >
                <DropdownSearch
                  label='End Beneficiary'
                  name='recipient'
                  type={clientInfo.type}
                  country={clientInfo.target_country_id}
                  toggle={recipientType === 'p' ? toggleShowAddIndividualRecipient : toggleShowAddBusinessRecipient}
                  value={receiver}
                  refetch={refetch}
                  onChange={onReceiverChange}
                />
                <IconButton
                  sx={{ height: '60px', flexGrow: 0 }}
                  aria-label='add'
                  onClick={recipientType === 'p' ? toggleShowAddIndividualRecipient : toggleShowAddBusinessRecipient}
                  // color={'red'}
                >
                  <Iconify icon={'eva:plus-square-outline'} width={40} height={30} />
                </IconButton>
              </Stack>
              {receiver && (
                <PreviewContainer title={'Recipient Details'}>
                  {content}
                  <PreviewItem title={'Phone'} content={receiver?.primary_contact_no} />
                  <PreviewItem title={'City'} content={receiver?.city?.name} />

                  <PreviewItem title={'Province'} content={receiver?.province?.name} />
                  <PreviewItem title={'Country'} content={receiver?.country?.name} />
                  <PreviewItem title={'Address'} content={receiver?.address} />
                </PreviewContainer>
              )}
            </Stack>
            <Divider orientation={matches ? '' : 'vertical'} variant='middle' flexItem={true} />
            <Stack direction={'column'} spacing={4} sx={{ flex: 1 }}>
              <Stack
                spacing={2}
                direction={'row'}
                sx={{
                  alignItems: 'flex-end',
                }}
              >
                <Stack
                  direction={'column'}
                  sx={{
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    flex: 1,
                  }}
                  spacing={0.5}
                >
                  <Typography>End Beneficiary Account</Typography>
                  <Autocomplete
                    disablePortal
                    id='account'
                    name='financeAccount'
                    value={selectedFinancialAccount}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(e, newValue) => {
                      setSelectedFinancialAccount(newValue);
                      formik.setFieldValue('financeAccount', newValue);
                    }}
                    options={financialAccounts}
                    getOptionLabel={(option) => option.display}
                    sx={{ flexGrow: 1, width: '100%' }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Stack>
                <IconButton
                  sx={{ height: '60px' }}
                  aria-label='add'
                  disabled={!receiver}
                  onClick={clientInfo?.type.split('2')[1] === 'p' ? toggleIndividualModal : toggleBusinessModal}
                  // color={'red'}
                >
                  <Iconify icon={'eva:plus-square-outline'} width={40} height={30} />
                </IconButton>
              </Stack>
              <Stack spacing={2}>
                {selectedFinancialAccount && <PreviewContainer title={'Financial Details'}>{financialDetails}</PreviewContainer>}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack direction={'row'} sx={{ justifyContent: 'space-between', paddingTop: '24px' }}>
          <Button className='btn white-btn action-button ' onClick={backClickHandler} appearance='subtle'>
            Back
          </Button>
          <Button className={`btn action-button primary_color text-white`} appearance='primary' onClick={nextClickHandler} disabled={!formik.isValid}>
            Next
          </Button>
        </Stack>
      </Box>

      {showAddIndividualRecipient && (
        <AddIndividualReceiverClient
          onClose={toggleShowAddIndividualRecipient}
          isOpen={showAddIndividualRecipient}
          isEdit={false}
          clientToEdit={{}}
          sender={{
            ...clientInfo?.sender,
            sender_type: clientInfo.type.split('2')[0] === 'p' ? 'individual' : 'business',
          }}
          onUserAdd={() => {
            setRefetch((state) => state + 1);
          }}
          fromRemittance={true}
        />
      )}
      {showAddBusinessRecipient && (
        <AddBusinessReceiverClient
          onClose={toggleShowAddBusinessRecipient}
          isOpen={showAddBusinessRecipient}
          isEdit={false}
          clientToEdit={{}}
          sender={{
            ...clientInfo?.sender,
            sender_type: clientInfo.type.split('2')[0] === 'p' ? 'individual' : 'business',
          }}
          onUserAdd={() => {
            setRefetch((state) => state + 1);
          }}
          fromRemittance={true}
        />
      )}
      {showIndividualFinancialModal && (
        <AddIndividualReceiverClient
          onClose={toggleIndividualModal}
          isOpen={showIndividualFinancialModal}
          isEdit={true}
          clientToEdit={receiver}
          onAddFinAc={onAddIndAcc}
          addFinancialOnly={true}
        />
      )}
      {showBusinessFinancialModal && (
        <AddBusinessReceiverClient
          onClose={toggleBusinessModal}
          isOpen={showBusinessFinancialModal}
          isEdit={true}
          clientToEdit={receiver}
          onAddFinAc={onAddBusAcc}
          addFinancialOnly={true}
        />
      )}
    </>
  );
};

export default RecipientInfo;

import React, { useState } from 'react';
import { styled } from '@material-ui/core/styles';
import { Typography, Stack, Box, Paper } from '@mui/material';
import Image from '../../../../components/image/Image';
import { getThumbnail } from '../../../../utils/common';
import { AddNewCard } from '../../../../components/addNewCard';
import { UploadDocument } from '../../../../components/uploadDocumentModal';
import { DocumentCard } from '../../../../components/documentCard';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { merchantApi } from '../../../../redux/api/apiConfig';
import { REMITTANCE_OUTGOING, SENDER_DOCUMENT } from '../../../../ApiUrl';
import { useCallback } from 'react';
import { ButtonPrimary } from '../../../../components';
import StatusModal from '../../../remittance/inward/components/statusModal/StatusModal';
import { useHistory } from 'react-router-dom';
import { Routes } from '../../../../routes';

const MyPaper = styled(Paper)({
  borderRadius: 8,
  borderColor: '#000',
  backgroundColor: '#F0F0F0',
  padding: '1rem',
});
const DocumentDetails = ({ transaction, getTransasction, isInward }) => {
  const [showDocumentUpload, setShowDocumentUpload] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState([]);
  const [senderDocs, setSenderDocs] = useState([]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);

  const history = useHistory();

  const {
    sender_document,
    current_status: { can_update_document = false },
    sender,
  } = transaction;

  const getAllSenderDocuments = useCallback(async () => {
    try {
      const response = await merchantApi.get(SENDER_DOCUMENT, {
        params: {
          sender: sender.id,
        },
      });
      setSenderDocs(response.data);
      let senderDocIds = sender_document.map((doc) => doc.id);
      setSelectedDocuments(senderDocIds);
      setSenderDocs(response.data);
      console.log(response);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
    }
  }, [sender.id, sender_document]);

  useEffect(() => {
    if (!isInward && can_update_document) {
      getAllSenderDocuments();
    } else {
      setSenderDocs(sender_document);
    }
  }, [sender_document, getAllSenderDocuments, can_update_document, isInward]);

  const toggleDocumentUpload = () => {
    setShowDocumentUpload((prevState) => !prevState);
  };

  const toggleStatusModal = () => {
    setShowStatusModal((prevState) => !prevState);
  };

  const onDocumentSelect = (id) => {
    let newDocArray = [...selectedDocuments];
    if (newDocArray.includes(id)) {
      newDocArray = newDocArray.filter((docId) => Number(docId) !== Number(id));
    } else {
      newDocArray.push(Number(id));
    }
    setSelectedDocuments(newDocArray);
  };

  const updateClickHandler = async (status, remarks) => {
    try {
      setUpdateLoading(true);
      const response = await merchantApi.patch(`${REMITTANCE_OUTGOING}${transaction.id}/`, {
        sender_document: selectedDocuments,
        status,
        remarks,
        // status:
      });
      console.log(response);
      setUpdateLoading(false);
      getTransasction();
      toggleStatusModal();
      history.push(Routes.OutwardRemittance.path);
      // getCounts();
    } catch (error) {
      setUpdateLoading(false);
      toast.error(error?.data?.data?.message || 'Something went wrong');
    }
  };

  return (
    <>
      <Box sx={{ marginTop: '1.5rem' }}>
        <MyPaper sx={{ flex: '1' }} elevation={0}>
          <Stack direction={'column'} spacing={2} sx={{ flex: 1 }}>
            <Typography variant={'h6'}>Documents</Typography>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 200px))',
                gridGap: '1rem',
                width: '100%',
              }}
            >
              {isInward ? (
                senderDocs?.map((document, i) => {
                  return (
                    <a href={document.document} target='_blank' rel='noopener noreferrer'>
                      <Image
                        key={document.id}
                        alt='file preview'
                        src={getThumbnail(document.document)}
                        title={`${document?.name ?? ''} (${document?.document_type?.name ?? ''})`}
                        sx={{
                          borderRadius: 1,
                          width: '200px',
                          height: '150px',
                          cursor: 'pointer',
                        }}
                      />
                    </a>
                  );
                })
              ) : (
                <>
                  {can_update_document
                    ? senderDocs?.map((document, i) => {
                        return (
                          <DocumentCard
                            isSelected={selectedDocuments.includes(document.id)}
                            document={document}
                            onDocumentSelect={onDocumentSelect}
                            canDelete={false}
                          />
                        );
                      })
                    : senderDocs?.map((document, i) => {
                        return (
                          <a href={document.document} target='_blank' rel='noopener noreferrer'>
                            <Image
                              key={document.id}
                              alt='file preview'
                              src={getThumbnail(document.document)}
                              title={`${document?.name ?? ''} (${document?.document_type?.name ?? ''})`}
                              sx={{
                                borderRadius: 1,
                                width: '200px',
                                height: '150px',
                                cursor: 'pointer',
                              }}
                            />
                          </a>
                        );
                      })}
                  {can_update_document && <AddNewCard onClick={toggleDocumentUpload} />}
                </>
              )}
            </Box>
          </Stack>

          {!isInward && can_update_document ? (
            <Stack
              sx={{
                width: 'fit-content',
                marginLeft: 'auto',
                marginTop: '1rem',
              }}
            >
              <ButtonPrimary onClick={toggleStatusModal} disabled={selectedDocuments.length === 0}>
                Update
              </ButtonPrimary>
            </Stack>
          ) : (
            ''
          )}
        </MyPaper>
      </Box>
      {showDocumentUpload && (
        <UploadDocument isOpen={showDocumentUpload} onClose={toggleDocumentUpload} senderId={sender.id} getSenderAccounts={getAllSenderDocuments} />
      )}
      {showStatusModal && (
        <StatusModal
          isOpen={showStatusModal}
          onClose={toggleStatusModal}
          updateClickHandler={updateClickHandler}
          mtoPatchLoading={updateLoading}
          status={transaction?.current_status}
          canChangeTo={transaction?.current_status?.sender_can_change_to}
        />
      )}
    </>
  );
};

export default DocumentDetails;

import React, { useState, useEffect, useCallback } from 'react';
import 'rsuite/dist/styles/rsuite-default.css';

import usePageTitle from '../../hooks/usePageTitle';
import { Box, Card } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ChatSidebar, ChatWindow } from './components';
import { useHistory, useParams } from 'react-router-dom';
import { Routes } from '../../routes';
import {
  getAllTickets,
  getConversation,
  resetActiveConversation,
} from '../../redux/actions/chat';
import { Label } from '../../components';
import { toast } from 'react-toastify';

const UserKyc = () => {
  const [openOnly, setOpenOnly] = useState(true);
  const [categoryValue, setCategoryValue] = useState('');
  const { conversationKey } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { activeConversationId, tickets } = useSelector((state) => state.chat);

  const selectedConversation = useSelector(() => {
    if (activeConversationId && tickets.length > 0) {
      return tickets.find((ticket) => ticket.id === activeConversationId);
    }

    return {
      id: '',
      replies: [],
    };
  });

  useEffect(() => {
    dispatch(getAllTickets());
  }, [dispatch]);
  useEffect(() => {
    const getDetails = async () => {
      try {
        await dispatch(getConversation(conversationKey));
      } catch (error) {
        toast.error(error?.data?.data?.message || 'Something went wrong');
        console.error(error);
        history.push(Routes.UserChatGeneral.path);
      }
    };

    if (conversationKey) {
      getDetails();
    } else if (activeConversationId) {
      dispatch(resetActiveConversation());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationKey]);

  const toggleOpenTickets = (event) => {
    setOpenOnly((prev) => !prev);
    if (!openOnly && selectedConversation.status === 'closed') {
      history.push(Routes.UserChatGeneral.path);
    }
  };
  const detailView = !!selectedConversation && !!activeConversationId;

  usePageTitle('Customer Support', '');
  let TABS = [
    { value: '', label: 'All', color: 'complete', count: 0 },
    {
      value: 'support',
      label: 'Support',
      color: 'application submitted',
      count: 0,
    },

    {
      value: 'kyc',
      label: 'KYC',
      color: 'pending',
      count: 0,
    },
  ];

  const onCategoryFilterChanges = useCallback(
    (newStatus) => {
      setCategoryValue(newStatus);
      if (newStatus !== selectedConversation.type) {
        history.push(Routes.UserChatGeneral.path);
      }
    },
    [selectedConversation, history]
  );

  const counts = tickets.reduce(
    (prev, curr) => {
      switch (curr.type) {
        case 'support':
          prev[0] = prev[0] + 1;
          return prev;
        case 'kyc':
          prev[1] = prev[1] + 1;
          return prev;
        default:
          break;
      }
    },
    [0, 0]
  );

  TABS[0].count = counts[0] + counts[1];
  TABS[1].count = counts[0];
  TABS[2].count = counts[1];
  console.log(counts, tickets);

  return (
    <Box sx={{ padding: '0 24px 24px' }}>
      <Card sx={{ height: '72vh', display: 'flex' }}>
        <ChatSidebar
          toggleOpenTickets={toggleOpenTickets}
          openOnly={openOnly}
          category={categoryValue}
          tabs={TABS}
          onCategoryFilterChanges={onCategoryFilterChanges}
          // conversations={tickets}
          // activeConversationId={activeConversationId}
          // detailView={detailView}
        />
        <ChatWindow
          selectedConversation={selectedConversation}
          openOnly={openOnly}
          category={categoryValue}
        />
      </Card>
    </Box>
  );
};

export default UserKyc;

import React, { useState } from 'react';

import { Box, Card, Grid, Stack, Switch, Typography, FormControlLabel, TextField } from '@mui/material';

import { connect } from 'react-redux';
import { getStatus } from '../../../../redux/actions/kybActions';
import { Loader, Button } from 'rsuite';
import axios from 'axios';
import { KYB_START, UPLOAD_KYB_DOC } from '../../../../ApiUrl';

import { toast } from 'react-toastify';

const KybPreview = ({ setStage, handleNext, handleBack, docInfo, businessInfo, getStatus }) => {
  const {
    country,
    province,
    address,
    city,
    postal_code,
    name,
    no_of_store,
    category,
    business_subcategory,
    registration_no,
    business_type,
    company_role,
    web_url,
    social_security_no,
  } = businessInfo;

  const [loading, setLoading] = useState(false);

  let token = localStorage.getItem('token');

  const onSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${KYB_START}`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      await getStatus();
      setStage('welcome');
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.response.data.message || 'Something went wrong');
    }
    setLoading(false);
  };

  console.log(docInfo);
  return (
    <Grid style={{ width: '100%' }} container spacing={3}>
      <Grid item xs={12} md={4}>
        <Box sx={{ mb: 5 }}>
          <Stack direction='column' spacing={4}>
            <Stack spacing={2}>
              <Typography variant='subtitle2'>Front Side</Typography>
              <ImageThumb
                image={docInfo?.frontEdit ? docInfo?.fileData?.get('front_part_of_document') : docInfo?.frontUrl}
                isEdit={docInfo.frontEdit}
              />
            </Stack>
            <Stack spacing={2}>
              <Typography variant='subtitle2'>Back Side</Typography>
              <ImageThumb image={docInfo?.backEdit ? docInfo?.fileData?.get('back_part_of_document') : docInfo?.backUrl} isEdit={docInfo.backEdit} />
            </Stack>
          </Stack>
        </Box>
      </Grid>

      <Grid item xs={12} md={8}>
        <Box
          sx={{
            display: 'grid',
            columnGap: 2,
            rowGap: 3,
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(2, 1fr)',
            },
          }}
        >
          <TextField disabled value={country} name='country' label='Country' />
          <TextField disabled value={province} name='province' label='Province' />
          <TextField disabled value={business_type} name='company_type' label='Company Type' />
          <TextField disabled value={name} name='business_name' label='Business Name' />
          <TextField disabled value={registration_no} name='registration_no' label='Registration Number' />
          <TextField disabled value={company_role} name='company_role' label='Company Role' />
          <TextField disabled value={address} name='address' label='Address' />
          <TextField disabled value={city} name='city' label='City' />
          <TextField disabled value={postal_code} name='postal_code' label='Postal Code' />
          <TextField disabled value={web_url} name='website' label='Website' />
          <TextField disabled value={no_of_store} name='no_of_store' label='Business Size' />
          <TextField disabled value={category} name='category' label='Business Category' />
          <TextField disabled value={business_subcategory} name='sub_category' label='Business Subcategory' />
          <TextField disabled value={social_security_no} name='social_security_no' label='Social Security Number' />
        </Box>

        <Stack direction='row' alignItems='flex-end' sx={{ mt: 3 }}>
          <Button className='btn white-btn action-button basis-30' onClick={handleBack} appearance='subtle'>
            Back
          </Button>
          <Button
            onClick={() => {
              // kybFormik.handleSubmit();
              onSubmit();
            }}
            // loading={updatingMerchantInfo}
            // disabled={!kybFormik.isValid}
            style={{ minHeight: '40px', maxWidth: 'fit-content' }}
            appearance='primary'
            className={`btn action-button primary_color text-white`}
          >
            <span>Confirm</span>
          </Button>
        </Stack>
      </Grid>
    </Grid>
  );
};

// export default KybPreview;

const ImageThumb = ({ image = '', isEdit }) => {
  console.log(image, isEdit);
  return <img src={isEdit ? URL.createObjectURL(image || {}) : image} alt='Doc' />;
};

function mapStateToProps({ kybReducer }) {
  const { status } = kybReducer;
  return {
    data: status.data,
  };
}

const actionCreators = {
  getStatus,
};

export default connect(mapStateToProps, actionCreators)(KybPreview);

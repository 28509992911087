import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Loader } from 'rsuite';
import { merchantApi } from '../../redux/api/apiConfig';
import * as API from '../../ApiUrl';

const Balance = () => {
  const [loading, setLoading] = useState(false);
  const [userBalance, setUserBalance] = useState(0);
  const [userCoinBalances, setUserCoinBalances] = useState([]);
  const [userCurrency, setUserCurrency] = useState('');

  const getBalance = (coinId = null) => {
    setLoading(true);
    let token = localStorage.getItem('token');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    let apiUrl = `${API.GET_BALANCE}`;
    if (coinId) {
      apiUrl += `?coin=${coinId}`;
    }
    merchantApi
      .get(apiUrl, config)
      .then((response) => {
        if (response && response.data) {
          if (response.data?.data) {
            setUserCoinBalances(response.data.data.balance_in_coins);
            setUserBalance(response.data.data.total_balance);
            setUserCurrency(response.data.data.currency.code);
          } else {
            toast.error(response.data.message);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error('There was an error getting your balance information. Please try again!');
      });
  };

  useEffect(() => {
    getBalance();
  }, []);

  return (
    <>
      {loading && <Loader backdrop content='loading...' vertical />}
      <div className='page-wrapper mt-4'>
        <div className='overviews'>
          <h5 className='font-700 mb-3'>Overview</h5>
          <div className='row '>
            <div className='col-lg-3 col-md-12'>
              <div className='white-box analytics-info cards  mb-3'>
                <div className='sem_title d-flex align-items-center justify-content-between'>
                  <h3 className='box-title font-16 text-cy'>Total Balance</h3>{' '}
                </div>
                <div className='currecny mt-3'>
                  <h3 className='font-700 m-0 font-18 font-fam'>
                    {userBalance} {userCurrency}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className='row '>
            {userCoinBalances &&
              userCoinBalances.map((item) => {
                return (
                  <div className='col-lg-3 col-md-12' key={`${item.coin}_balance`}>
                    <div className='white-box analytics-info cards  mb-3'>
                      <div className='sem_title d-flex flex-direction-row align-items-center justify-content-between'>
                        <h3 className='box-title font-16 text-cy'>{item.coin}</h3>
                        <h3 className='font-700 m-0 font-18 font-fam'>{item.value}</h3>
                      </div>
                      <div className='sem_title d-flex flex-direction-row align-items-center justify-content-between mt-4'>
                        <h3 className='box-title font-16 text-cy'>{userCurrency}</h3>
                        <h3 className='font-700 m-0 font-18 font-fam'>{item.value_in_currency}</h3>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Balance;

import React from 'react';
import { Stack } from '@mui/material';
import HistoryItem from './HistoryItem';

const StatusHistory = ({ statuses, type }) => {
  console.log(statuses);
  return (
    <Stack spacing={3} alignItems='center'>
      {statuses.map((status) => (
        <HistoryItem status={status} type={type} />
      ))}
    </Stack>
  );
};

export default StatusHistory;

import React from 'react';
import { Box } from '@material-ui/core';
import { PreviewContainer, PreviewItem } from '../../../../components';
import { formatCurrency, formatWithoutTimezone } from '../../../../utils/common';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Stack, Divider } from '@mui/material';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';

export const ViewDetails = React.forwardRef(({ transaction, backClickHandler, isInward }, ref) => {
  // const { depositTypes } = useSelector((state) => state.configReducer);
  const { coins = [] } = useSelector((state) => state.walletsReducer);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    reference_number,
    sender,
    receiver,
    receiver_account,
    send_amount,
    send_currency,
    receive_amount,
    receive_currency,
    created_date,
    created_user,
    deposit_type,
    purpose,
    type,
    receipt_mto,
    sender_commission,
    receiver_commission,
    stb_fee,
    total_fee,
    customer_paid_amount,
  } = transaction;

  // let remittanceContent;

  // const purposeTypes = JSON.parse(localStorage.getItem('purposeTypes'));
  let remittanceContent;

  if (isInward) {
    remittanceContent = (
      <>
        <PreviewItem title={'Payment Order Number'} content={reference_number} />
        <PreviewItem title={'Remittance Amount'} content={formatCurrency(send_amount, send_currency.decimal_place, send_currency.symbol)} />
        <PreviewItem title={'Receiver Fee'} content={formatCurrency(receiver_commission, send_currency.decimal_place, send_currency.symbol)} />
        <PreviewItem
          title={'Total Amount'}
          content={formatCurrency(Number(send_amount) + Number(receiver_commission), send_currency.decimal_place, send_currency.symbol)}
        />
        <PreviewItem title={'Amount to Payout'} content={formatCurrency(receive_amount, receive_currency.decimal_place, receive_currency.symbol)} />
        <PreviewItem title={'Purpose of Remittance'} content={purpose} />

        <PreviewItem title={'Sender MTO'} content={sender.mto.name} />
        <PreviewItem title={'Receiver MTO'} content={receipt_mto?.name} />
        <PreviewItem
          title={'Conversion Rate'}
          content={`1 ${send_currency.symbol} = ${Number((receive_amount / send_amount).toFixed(receive_currency.decimal_place))} ${
            receive_currency.symbol
          }`}
        />
        <PreviewItem title={'Type'} content={type} />
        <PreviewItem title={'Date'} content={formatWithoutTimezone(created_date, 'dd MMM yyyy HH:mm a')} />
      </>
    );
  } else {
    remittanceContent = (
      <>
        <PreviewItem title={'Payment Order Number'} content={reference_number} />
        <PreviewItem title={'Remittance Amount'} content={formatCurrency(send_amount, send_currency.decimal_place, send_currency.symbol)} />
        <PreviewItem title={'Sender MTO Fee'} content={formatCurrency(sender_commission, send_currency.decimal_place, send_currency.symbol)} />
        <PreviewItem title={'Recipient MTO Fee'} content={formatCurrency(receiver_commission, send_currency.decimal_place, send_currency.symbol)} />
        <PreviewItem title={'Other Fee'} content={formatCurrency(stb_fee, send_currency.decimal_place, send_currency.symbol)} />
        <PreviewItem title={'Total Fee'} content={formatCurrency(Number(total_fee), send_currency.decimal_place, send_currency.symbol)} />
        <PreviewItem
          title={'Total Deposit Amount'}
          content={formatCurrency(customer_paid_amount, send_currency.decimal_place, send_currency.symbol)}
        />
        <PreviewItem title={'Amount to Payout'} content={formatCurrency(receive_amount, receive_currency.decimal_place, receive_currency.symbol)} />
        <PreviewItem title={'Deposit Type'} content={deposit_type} />
        <PreviewItem title={'Purpose of Remittance'} content={purpose} />

        <PreviewItem title={'Sender MTO'} content={sender.mto.name} />
        <PreviewItem title={'Receiver MTO'} content={receipt_mto?.name} />
        <PreviewItem
          title={'Conversion Rate'}
          content={`1 ${send_currency.symbol} = ${Number((receive_amount / send_amount).toFixed(receive_currency.decimal_place))} ${
            receive_currency.symbol
          }`}
        />
        <PreviewItem title={'Type'} content={type} />
        <PreviewItem title={'Date'} content={formatWithoutTimezone(created_date, 'dd MMM yyyy HH:mm a')} />
        <PreviewItem title={'Created By'} content={created_user} />
      </>
    );
  }

  const [senderType, recipientType] = type.split('2');
  let senderContent;
  if (senderType === 'p') {
    senderContent = (
      <>
        <PreviewItem title={'First Name'} content={sender?.first_name} />
        <PreviewItem title={'Last Name'} content={sender?.last_name} />
      </>
    );
  } else if (senderType === 'b') {
    senderContent = (
      <>
        <PreviewItem title={'Business Name'} content={sender?.business_name} />
        <PreviewItem title={'Primary Contact Name'} content={sender?.primary_contact_name} />
      </>
    );
  }

  let recipientContent;
  if (recipientType === 'p') {
    recipientContent = (
      <>
        <PreviewItem title={'First Name'} content={receiver?.first_name} />
        <PreviewItem title={'Last Name'} content={receiver?.last_name} />
      </>
    );
  } else if (recipientType === 'b') {
    recipientContent = (
      <>
        <PreviewItem title={'Business Name'} content={receiver?.business_name} />
        <PreviewItem title={'Primary Contact Name'} content={receiver?.primary_contact_name} />
      </>
    );
  }
  let financialDetails = '';
  const acType = receiver_account.type;
  if (acType === 'venmo') {
    financialDetails = (
      <>
        <PreviewItem title={'Venmo ID'} content={receiver_account?.venmo_id} />
      </>
    );
  } else if (acType === 'paypal') {
    financialDetails = (
      <>
        <PreviewItem title={'PayPal Email'} content={receiver_account?.email} />
      </>
    );
  } else if (acType === 'bank') {
    const { name, account_no, lei_code, branch, iban, swift_code, transit_number, aba_number, address, ifsc_code } = receiver_account.bank;
    financialDetails = (
      <>
        <PreviewItem title={'Bank Name'} content={name} />
        <PreviewItem title={'Branch'} content={branch} /> <PreviewItem title={'Account Number'} content={account_no} />{' '}
        <PreviewItem title={'LEI Code'} content={lei_code} /> <PreviewItem title={'IBAN'} content={iban} />{' '}
        <PreviewItem title={'SWIFT BIC Code'} content={swift_code} />
        <PreviewItem title={'Transit Number'} content={transit_number} />
        <PreviewItem title={'ABA Routing Number'} content={aba_number} />
        <PreviewItem title={'IFSC Code'} content={ifsc_code} />
        <PreviewItem title={'Address'} content={address} />
      </>
    );
  }

  return (
    <>
      <Box ref={ref} sx={{ padding: '24px ' }}>
        <Stack sx={{ marginTop: '1.5rem' }} spacing={3} direction={'column'}>
          <PreviewContainer title={'Remittance Details'}>{remittanceContent}</PreviewContainer>
          {/* <Divider
              orientation={matches ? '' : 'vertical'}
              variant="middle"
              flexItem={true}
            /> */}
          <PreviewContainer title={'Sender Details'}>
            {senderContent}
            <PreviewItem title={'Phone'} content={sender?.primary_contact_no} />
            <PreviewItem title={'City'} content={sender?.city?.name} />

            <PreviewItem title={'Province'} content={sender?.province?.name} />
            <PreviewItem title={'Country'} content={sender?.country?.name} />
            <PreviewItem title={'Address'} content={sender?.address} />
          </PreviewContainer>
          {/* <Divider
              orientation={matches ? '' : 'vertical'}
              variant="middle"
              flexItem={true}
            /> */}
          {/* <Stack
              spacing={!matches ? 2 : 0}
              direction={{
                xs: 'column',
                sm: 'row',
              }}
              sx={{ flex: 1 }}
            > */}
          <PreviewContainer title={'Recipient Details'}>
            {recipientContent}
            <PreviewItem title={'Phone'} content={receiver?.primary_contact_no} />
            <PreviewItem title={'City'} content={receiver?.city?.name} />

            <PreviewItem title={'Province'} content={receiver?.province?.name} />
            <PreviewItem title={'Country'} content={receiver?.country?.name} />
            <PreviewItem title={'Address'} content={receiver?.address} />
          </PreviewContainer>
          {/* {!matches && (
                <Divider
                  orientation={matches ? '' : 'vertical'}
                  variant="middle"
                  flexItem={true}
                />
              )} */}
          <PreviewContainer title={'Financial Details'}>{financialDetails}</PreviewContainer>
          {/* </Stack> */}
        </Stack>
      </Box>
    </>
  );
});

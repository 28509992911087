import React, { useEffect } from 'react';
import { Col, Row } from '@themesberg/react-bootstrap';
import * as yup from 'yup';
import { useFormik } from 'formik';
import axios from 'axios';
import * as API from '../../../ApiUrl';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Stack, TextField, MenuItem, Button } from '@mui/material';
import { textFieldLabelProps } from '../../../utils/common';
import { TextFieldComponent, TextFieldSelect } from '../../../components';

const validationSchema = yup.object({
  message: yup
    .string('Enter your message')
    .min(1, 'Please enter valid message')
    .max(300, 'Message is only 300 words')
    .required('Message is required!'),
  subject: yup.string('Enter your subject').min(1, 'Enter Valid Subject').required('Subject is required'),
  priority: yup.string('Select Priority').required('Priority is required'),
  category: yup.string('Select Category').required('Category is required'),
});

const NewTicketModal = ({ isOpen, onClose, getTickets }) => {
  const [attachment, setAttachment] = useState('');
  const [loading, setloading] = useState(false);
  const [cate, setCate] = useState([]);
  const [ticketPriority, setTicketPriority] = useState([]);

  let token = localStorage.getItem('token');
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  const getCate = async () => {
    try {
      const response = await axios.get(`${API.SUPPORT_CATEGORY}`, config);
      setloading(false);
      setCate(response.data);
    } catch (err) {
      toast.error(err?.data?.data?.message || 'Something went wrong');
    }
  };

  const getPriorityOptions = async () => {
    try {
      const response = await axios.get(`${API.TICKET_PRIORITY}`, config);
      console.log(response.data);
      setTicketPriority(response.data.results.sort((a, b) => b.id - a.id));
    } catch (err) {
      toast.error(err?.data?.data?.message || 'Something went wrong');
    }
  };

  useEffect(() => {
    getCate();
    getPriorityOptions();
  }, []);

  function handleImage(event) {
    setAttachment(event.target.files[0]);
    console.log('image file details', event.target.files[0]);
  }

  const onSubmit = async (values) => {
    setloading(true);
    let user_info = JSON.parse(localStorage.getItem('user-info'));
    const data = { ...values, user: user_info.user_id };
    console.log('data', data);
    try {
      if (attachment) {
        var filedata = new FormData();
        filedata.append('attachment', attachment);
        filedata.append('user', data.user);
        filedata.append('category', data.category);
        filedata.append('subject', data.subject);
        filedata.append('message', data.message);
        const response = await axios.post(`${API.TICKET}`, filedata, config);

        formik.resetForm();
      } else {
        formik.resetForm();
        const response = await axios.post(`${API.TICKET}`, data, config);
        console.log(response);
      }
      formik.resetForm();
      toast.success('Ticket created successfully');
      getTickets();
      onClose();
    } catch (err) {
      setloading(false);
      toast.error(err?.data?.data?.message || 'Something went wrong');
    }

    setloading(false);
  };

  const formik = useFormik({
    initialValues: {
      category: '',
      priority: '',
      subject: '',
      message: '',
      // attachment:"",
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit,
    // isInitialValid: false,
    validateOnMount: true,
  });

  return (
    <Dialog
      open={isOpen}
      maxWidth={'sm'}
      onClose={onClose}
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
          width: '628px',
          minHeight: '632px',
          maxHeight: '80vh',
        },
      }}
    >
      <DialogTitle className='text-center'>
        <p className='dialog-title'>Open New Support Ticket</p>
      </DialogTitle>

      <form onSubmit={formik.handleSubmit}>
        <DialogContent className='mt-3'>
          <Stack spacing={1} direction={'column'}>
            <TextFieldSelect
              formik={formik}
              key='category'
              name='category'
              label='Category'
              options={cate}
              value={formik.values.category}
              render={(category, i) => (
                <MenuItem key={i + category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              )}
            />
            <TextFieldSelect
              formik={formik}
              key='priority'
              name='priority'
              label='Priority'
              options={ticketPriority}
              value={formik.values.priority}
              render={(priority, i) => (
                <MenuItem key={i + priority.id} value={priority.id}>
                  {priority.name}
                </MenuItem>
              )}
            />

            <TextFieldComponent formik={formik} name={'subject'} label={'Subject'} type={'text'} />

            <TextFieldComponent formik={formik} name={'message'} label={'Message'} type={'text'} multiline maxRows={3} minRows={3} />

            <Col xl={12} className='mb-2'>
              <p className='fw-bold'>Add Attachment</p>
              <div>
                <Button
                  component='label'
                  variant='outlined'
                  className={`btn action-button primary_color text-white basis-70`}
                  // startIcon={<UploadFileIcon />}
                  sx={{ marginRight: '1rem', marginLeft: '0 !important' }}
                >
                  Upload File
                  <input type='file' accept='image/*, application/pdf ' name='attachment' hidden id='attachment' onChange={handleImage} />
                </Button>
                <span>Max 12 MB in size</span>
              </div>
            </Col>

            <Col xl={2} xs={2}>
              {attachment && <ImageThumb image={attachment} />}
            </Col>
          </Stack>
        </DialogContent>

        <DialogActions className='footer-content'>
          <Button className='btn white-btn action-button basis-30' onClick={onClose} appearance='subtle'>
            Cancel
          </Button>
          <Button
            className={`btn action-button primary_color text-white basis-70 ${!formik.isValid ? 'disabled' : null}`}
            appearance='primary'
            type={'submit'}
            loading={loading.toString()}
            disabled={loading || !formik.isValid}
          >
            {!loading ? 'Open new ticket' : 'Loading...'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

const ImageThumb = ({ image }) => {
  return <img src={URL.createObjectURL(image)} alt={image.name} />;
};
export default NewTicketModal;

import React, { useState, useEffect, useCallback } from 'react';
import { merchantApi } from '../redux/api/apiConfig';
import { TextField } from '@material-ui/core';
import { ADD_RECEIVER_BUS, ADD_RECEIVER_IND, ADD_SENDER_BUS, ADD_SENDER_IND } from '../ApiUrl';
// @mui
import { Stack, Typography, Autocomplete } from '@mui/material';
import SearchNotFound from './SearchNotFound';
import { toast } from 'react-toastify';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default React.memo(function DropdownSearch({ label, name, toggle, type, country, setValue = () => {}, value, refetch, onChange = () => {} }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [userType, setUserType] = useState('p');
  const [searchResults, setSearchResults] = useState([]);

  const getOption = useCallback(
    (value) => {
      if (userType === 'p') {
        const { first_name, last_name, country } = value;
        return `${first_name} ${last_name} ${country ? `(${country.name})` : ''}`;
      } else if (userType === 'b') {
        const { business_name, country } = value;
        return `${business_name} ${country ? `(${country.name})` : ''}`;
      }
    },
    [userType]
  );

  const getDropdownOptions = useCallback(async (url, country) => {
    try {
      const response = await merchantApi.get(`${url}`, {
        params: { limit: 1000, country },
      });
      const activeUsers = response.data.results.filter((user) => user.status === 'active');
      setSearchResults(activeUsers);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      console.error(error);
    }
  }, []);

  useEffect(() => {
    let url;
    if (name === 'sender') {
      const newUserType = type.split('2')[0];
      setUserType(newUserType);
      if (newUserType === 'p') {
        url = ADD_SENDER_IND;
      } else {
        url = ADD_SENDER_BUS;
      }
    } else if (name === 'recipient') {
      const newUserType = type.split('2')[1];
      setUserType(newUserType);
      if (newUserType === 'p') {
        url = ADD_RECEIVER_IND;
      } else {
        url = ADD_RECEIVER_BUS;
      }
    }
    // if (value) {
    //   setSelectedValue(getOption(value));
    // }
    getDropdownOptions(url, country);
  }, [refetch, type, name, value, getOption, getDropdownOptions, country]);

  console.log('ewrr');

  return (
    <Autocomplete
      disablePortal
      sx={{ flexGrow: 1 }}
      id='search'
      getOptionLabel={(option) => {
        return getOption(option);
      }}
      value={value}
      isOptionEqualToValue={(option, value) => {
        return option?.id === value?.id;
      }}
      options={searchResults}
      onInputChange={(e, value) => setSearchQuery(value)}
      onChange={(e, value) => {
        if (value) {
          // setSelectedValue(getOption(value));
          // setValue(value);
          // formik.setFieldValue(name, value);
          onChange(value);
        }
      }}
      noOptionsText={<SearchNotFound toggle={toggle} searchQuery={searchQuery} />}
      renderInput={(params) => {
        return (
          <Stack
            direction={'column'}
            sx={{
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              flexGrow: 1,
            }}
            spacing={1}
          >
            <Typography>{label}</Typography>
            <TextField {...params} variant='outlined' name={name} placeholder={`Search ${label}`} type='text' />
          </Stack>
        );
      }}
      renderOption={(props, option) => {
        const { id } = option;
        return (
          <li {...props} key={id}>
            <Typography variant='body2'>{getOption(option)}</Typography>
          </li>
        );
      }}
    />
  );
});

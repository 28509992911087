import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'rsuite/dist/styles/rsuite-default.css';
import { Routes } from '../../../routes';
import { send } from '../../../utils/xrpl_functions';
import * as API from '../../../ApiUrl';
import { RemittanceTable } from './components';
import SelectMtoModal from './components/addRemittance/SelectMtoModal';
import PassphraseModal from './components/addRemittance/PassphraseModal';
import { merchantApi } from '../../../redux/api/apiConfig';
import StatusModal from '../inward/components/statusModal/StatusModal';
import { ButtonPrimary, ButtonResetFilter, Iconify } from '../../../components';
import { format } from 'date-fns';
import { Box, Divider, Stack, MenuItem, TextField, InputAdornment, Autocomplete } from '@mui/material';
import usePageTitle from '../../../hooks/usePageTitle';
import { reportTimePeriods } from '../../reports/common';
import { formatDate } from '../../../utils/common';
import { FilterDropdown } from '../../reports/components';
import { StyledDatePicker } from '../../../components/datePicker';
import * as actionTypes from '../../../redux/actionTypes';

function OutwardRemittance() {
  const [translist, setTranslist] = useState([]);
  const [fetchTranslistLoading, setFetchTranslistLoading] = useState(false);
  const [transactionData, setTransactionData] = useState('');
  const [showMtoModal, setShowMtoModal] = useState(false);
  const [selectedMto, setSelectedMto] = useState(null);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [mtoPatchLoading, setMtoPatchLoading] = useState(false);
  const [showPassphraseModal, setShowPassphraseModal] = useState(false);
  const [passphraseLoading, setPassphraseLoading] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);

  const { walletsReducer, configReducer } = useSelector((state) => state);
  const { wallets = [], coins = [] } = walletsReducer;
  const { remittanceStatuses } = configReducer;

  const history = useHistory();
  const dispatch = useDispatch();

  const filters = useSelector((state) => state.filters.remittances.outward.outwardRemittance);

  usePageTitle('Outward Remittance', 'View your remittance requests');

  const newRemittanceClickHander = () => {
    history.push(Routes.AddOutwardRemittance.path);
  };
  const role = useSelector((state) => state.profileReducer?.user?.role?.key);
  const permission = useSelector((state) => state.profileReducer?.permission);

  const handleClearFilters = () => {
    dispatch({ type: actionTypes.CLEAR_OUTWARD_REMITTANCE });
  };

  const getTrans = useCallback(async () => {
    setFetchTranslistLoading(true);
    const { calenderValue, pageSize, currentPage, search, coin, status } = filters;
    const created_date_before = formatDate(calenderValue[1], 'yyyy-MM-dd');
    const created_date_after = formatDate(calenderValue[0], 'yyyy-MM-dd');
    merchantApi
      .get(`${API.REMITTANCE_OUTGOING}`, {
        params: {
          offset: pageSize * currentPage,
          limit: pageSize,
          search,
          coin: coin?.id,
          status: status?.id,
          created_date_after,
          created_date_before,
        },
      })
      .then((response) => {
        setFetchTranslistLoading(false);
        if (response.data) {
          setTranslist(response.data);
        }
      })
      .catch((error) => {
        toast.error(error?.data?.data?.message || 'Something went wrong');
        setFetchTranslistLoading(false);
      });
  }, [filters]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getTrans();
    }, 500);
    return () => clearTimeout(timer);
  }, [filters.currentPage, filters.pageSize, filters.search, filters.coin, filters.status, filters.calenderValue, getTrans]);

  const toggleStatusModal = () => {
    setShowStatusModal((state) => !state);
  };

  const updateStatus = (transaction) => {
    if (transaction.current_status.can_update_document) {
      history.push({
        pathname: Routes.OutwardRemittance.path + `/details/${transaction.id}`,
        state: { isEdit: true },
      });
    }
    setTransactionData(transaction);
    toggleStatusModal();
  };

  const updateClickHandler = async (status, remarks) => {
    try {
      setMtoPatchLoading(true);
      const response = await merchantApi.patch(`${API.REMITTANCE_OUTGOING}${transactionData.id}/`, {
        status,
        remarks,
      });
      setMtoPatchLoading(false);
      toggleStatusModal();
      getTrans();
    } catch (err) {
      setMtoPatchLoading(false);
      toast.error(err?.data?.data?.message || 'Something went wrong');
    }
  };

  const viewDetailsHandler = (id) => {
    history.push(Routes.OutwardRemittanceBase.path + `/${id}`);
  };

  const toggleMtoModal = () => {
    setShowMtoModal((state) => !state);
  };

  const togglePassphraseModal = () => {
    setShowPassphraseModal((state) => !state);
  };

  const selectMtoClickHandler = (transaction) => {
    setSelectedTransaction(transaction);
    toggleMtoModal();
  };
  const nextClickHandler = async (mto) => {
    try {
      setMtoPatchLoading(true);
      setSelectedMto(mto);
      const response = await merchantApi.patch(`${API.REMITTANCE_OUTGOING}${selectedTransaction.id}/`, { receipt_mto: mto.id });

      setMtoPatchLoading(false);
      toggleModals();
    } catch (err) {
      setMtoPatchLoading(false);
      toast.error(err?.data?.data?.message || 'MTO creation failed');
    }
  };

  const passphraseSubmitHandler = async (passphrase) => {
    setPassphraseLoading(true);
    const { send_amount, receive_amount, receive_currency, send_currency } = selectedTransaction;

    const {
      address: fromAddress,
      coin: { issuer_address: issuerSend, symbol: senderCurrency },
    } = wallets.find((wallet) => wallet.coin.id === send_currency.id);
    const {
      address: toAddress,
      coin: { issuer_address: issuerRecv, symbol: receiverCurrency },
    } = selectedMto.wallet.find((wallet) => wallet.coin.id === receive_currency.id);
    try {
      const blob = await send(
        fromAddress,
        toAddress,
        passphrase,
        send_amount,
        receive_amount,
        senderCurrency,
        receiverCurrency,
        issuerSend,
        issuerRecv
      );
      if (blob) {
        const response = await merchantApi.post(API.TRANSACTION, {
          remittance: selectedTransaction.id,
          blob,
        });
        toast.success('Remittance created successfully');
        closeMtoModal();
      }
    } catch (err) {
      toast.error(err?.data?.data?.message || 'Something went wrong');
    } finally {
      setPassphraseLoading(false);
    }
  };

  const toggleModals = () => {
    togglePassphraseModal();
    toggleMtoModal();
  };

  const closeMtoModal = () => {
    setShowPassphraseModal(false);
    setShowMtoModal(false);
    getTrans();
    setSelectedMto(null);
  };

  const deleteRemittance = async (id) => {
    try {
      const response = await merchantApi.delete(`${API.REMITTANCE_OUTGOING}${id}/`);
      toast.success('Remittance deleted successfully');
      getTrans();
    } catch (err) {
      toast.error(err?.data?.data?.message || 'Something went wrong');
      console.log(err);
    }
  };

  const handleChangePage = (newPage) => {
    dispatch({
      type: actionTypes.UPDATE_OUTWARD_REMITTANCE,
      payload: { currentPage: newPage },
    });
  };

  const handleChangeRowsPerPage = (newSize) => {
    dispatch({
      type: actionTypes.UPDATE_OUTWARD_REMITTANCE,
      payload: { currentPage: 0, pageSize: newSize },
    });
  };

  return (
    <>
      <Box sx={{ padding: '0 24px 24px' }}>
        <Stack
          sx={{
            overflowX: { xs: 'auto' },
            overflowY: { xs: 'visible', md: 'unset' },
            padding: '5px 0',
          }}
          direction={'row'}
          justifyContent={'space-between'}
          gap={1}
          mt={2}
          width={'100%'}
        >
          <Stack sx={{ flexDirection: 'row', gap: 1 }} divider={<Divider orientation='vertical' flexItem sx={{ borderStyle: 'dashed' }} />}>
            <Stack sx={{ minWidth: '150px', flex: 1 }}>
              <FilterDropdown
                name='timeperiod'
                type='text'
                label='Timeperiod'
                options={reportTimePeriods}
                onChange={(e) => {
                  const name = e.target.value;
                  const period = reportTimePeriods.find((report) => report.name === name);

                  let toValue;
                  if (filters.calenderValue[1]) {
                    toValue = new Date(period.from) > new Date(filters.calenderValue[1]) ? null : filters.calenderValue[1];
                  } else {
                    toValue = null;
                  }
                  dispatch({
                    type: actionTypes.UPDATE_OUTWARD_REMITTANCE,
                    payload: {
                      calenderValue: [new Date(period.from), toValue],
                      period,
                    },
                  });
                }}
                value={filters?.period?.name}
                size={'small'}
                render={(period, i) => (
                  <MenuItem key={period.name} value={period.name}>
                    {period.name}
                  </MenuItem>
                )}
              />
            </Stack>
            <Stack sx={{ flexDirection: 'row', gap: 1 }}>
              <Stack sx={{ width: '176px', flex: 1 }}>
                <StyledDatePicker
                  value={filters.calenderValue[0]}
                  disableFuture
                  format={'dd/MM/yyyy'}
                  onChange={(newValue) => {
                    dispatch({
                      type: actionTypes.UPDATE_OUTWARD_REMITTANCE,
                      payload: {
                        calenderValue: [
                          new Date(format(newValue, 'yyyy/MM/dd')),
                          newValue > filters.calenderValue[1] ? undefined : filters.calenderValue[1],
                        ],
                      },
                    });
                  }}
                />
              </Stack>

              <Stack sx={{ width: '176px', flex: 1 }}>
                <StyledDatePicker
                  value={filters.calenderValue[1]}
                  disableFuture
                  // minDate={filters.calenderValue[0] ?? undefined}
                  format={'dd/MM/yyyy'}
                  onChange={(newValue) => {
                    dispatch({
                      type: actionTypes.UPDATE_OUTWARD_REMITTANCE,
                      payload: {
                        calenderValue: [
                          newValue < filters.calenderValue[0] ? undefined : filters.calenderValue[0],
                          new Date(format(newValue, 'yyyy/MM/dd')),
                        ],
                      },
                    });
                  }}
                />
              </Stack>
            </Stack>

            <Stack sx={{ flexDirection: 'row', gap: 1, flex: 1 }}>
              {coins && (
                <Stack sx={{ minWidth: '205px', flex: 1 }}>
                  <Autocomplete
                    id='currency'
                    value={filters.coin}
                    onChange={(e, newValue) => {
                      dispatch({
                        type: actionTypes.UPDATE_OUTWARD_REMITTANCE,
                        payload: {
                          currentPage: 0,
                          coin: newValue,
                        },
                      });
                    }}
                    options={coins}
                    getOptionLabel={(option) => `${option?.name}`}
                    renderInput={(params) => {
                      return <TextField placeholder='Select Currency' {...params} size='small' />;
                    }}
                  />
                </Stack>
              )}
              <Stack sx={{ minWidth: '200px', flex: 1 }}>
                <TextField
                  fullWidth
                  size='small'
                  value={filters.search}
                  onChange={(e) => {
                    dispatch({
                      type: actionTypes.UPDATE_OUTWARD_REMITTANCE,
                      payload: {
                        search: e.target.value,
                      },
                    });
                  }}
                  placeholder='Search...'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Iconify icon='eva:search-fill' sx={{ color: 'text.disabled' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Stack>

            <Stack sx={{ minWidth: '200px', flex: 1 }}>
              <Autocomplete
                id='status'
                value={filters.status}
                onChange={(e, newValue) => {
                  console.log(newValue);
                  dispatch({
                    type: actionTypes.UPDATE_OUTWARD_REMITTANCE,
                    payload: {
                      status: newValue ?? null,
                    },
                  });
                }}
                options={remittanceStatuses}
                getOptionLabel={(option) => option?.sender_name}
                sx={{ flexGrow: 1, width: '100%' }}
                renderInput={(params) => {
                  return <TextField placeholder='Select Status' {...params} size='small' />;
                }}
              />
            </Stack>
          </Stack>
          <Stack sx={{ flexDirection: 'row', gap: 1 }}>
            <ButtonResetFilter onClick={handleClearFilters} />
            {permission?.remittances?.outward?.allremittance?.permission?.add[role] && (
              <ButtonPrimary onClick={newRemittanceClickHander}>New Remittance</ButtonPrimary>
            )}
          </Stack>
        </Stack>
        <RemittanceTable
          translist={translist}
          fetchTranslistLoading={fetchTranslistLoading}
          viewDetails={viewDetailsHandler}
          selectMto={selectMtoClickHandler}
          deleteRemittance={deleteRemittance}
          updateStatus={updateStatus}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          filters={filters}
        />
      </Box>

      {showMtoModal && (
        <SelectMtoModal
          isOpen={showMtoModal}
          onClose={toggleMtoModal}
          nextClickHandler={nextClickHandler}
          mtoPatchLoading={mtoPatchLoading}
          createRemittanceRes={selectedTransaction}
          mto={selectedMto}
        />
      )}
      {showPassphraseModal && (
        <PassphraseModal
          isOpen={showPassphraseModal}
          onClose={closeMtoModal}
          backClickHandler={toggleModals}
          submitClickHandler={passphraseSubmitHandler}
          passphraseLoading={passphraseLoading}
        />
      )}
      {showStatusModal && (
        <StatusModal
          isOpen={showStatusModal}
          onClose={toggleStatusModal}
          updateClickHandler={updateClickHandler}
          mtoPatchLoading={mtoPatchLoading}
          status={transactionData?.current_status}
          canChangeTo={transactionData?.current_status?.sender_can_change_to}
        />
      )}
    </>
  );
}

export default OutwardRemittance;

import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Dialog, DialogTitle, DialogContent, DialogActions, Stack } from '@mui/material';
import { useFormik } from 'formik';
import { ButtonPrimary, ButtonSecondary, CurrencySelect, TextFieldComponent } from '..';
import { useSelector } from 'react-redux';
import { merchantApi } from '../../redux/api/apiConfig';
import { LIQUIDITY } from '../../ApiUrl';
import { toast } from 'react-toastify';

const LiquidityModal = ({ isOpen, onClose, nextClickHandler, coinToSelect = '' }) => {
  const [loading, setLoading] = useState(false);
  const [yupObject, setYupObject] = useState({
    liquidityAmount: yup.number('Enter amount').moreThan(0, 'Amount must be greater than 0').required('Amount is required!'),

    liquidityCurrency: yup.string('Select currency').required('Currency is required!'),
  });
  const [validationSchema, setValidationSchema] = useState(yup.object(yupObject));

  const { walletsReducer, profileReducer } = useSelector((state) => state);

  const { wallets } = walletsReducer;
  const {
    user: {
      mto: {
        currency: { code },
      },
    },
  } = profileReducer;
  useEffect(() => {
    const nativeWallet = wallets?.find((wallet) => wallet.coin.symbol === code);
    formik.setFieldValue('liquidityCurrency', nativeWallet?.coin?.id);
  }, [code, wallets]);

  const onRequest = async (values) => {
    setLoading(true);
    try {
      const response = await merchantApi.post(LIQUIDITY, {
        amount: values.liquidityAmount,
        remarks: values.remarks,
      });
      setLoading(false);
      toast.success('Liquidity request successful');
      onClose();
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error(error?.data?.data?.message || 'Something went wrong');
      // toast.error('Liquidity request failed');
    }
  };
  const formik = useFormik({
    initialValues: {
      liquidityCurrency: '',
      liquidityAmount: '',
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: onRequest,
    // isInitialValid: isEdit,
    validateOnMount: true,
  });

  const handleCloseModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose();
  };

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={'xs'}
      onClose={handleCloseModal}
      scroll={'body'}
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
        },
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>Add Liquidity</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent sx={{ paddingTop: '5px !important' }}>
          <Stack spacing={1} direction={'column'} alignItems={'center'}>
            <CurrencySelect formik={formik} options={wallets} amountName='liquidityAmount' currencyName='liquidityCurrency' disableOptions={true} />

            <TextFieldComponent formik={formik} name={'remarks'} label={'Remarks'} type={'text'} multiline maxRows={3} minRows={3} />
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            paddingX: '24px',
            paddingBottom: '15px',
          }}
        >
          <ButtonSecondary onClick={onClose}>Cancel</ButtonSecondary>
          <ButtonPrimary type={'submit'} disabled={!formik.isValid} loading={loading}>
            Request
          </ButtonPrimary>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default LiquidityModal;

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { async } from 'validate.js';
import { COUNTRY_LIST, CATEGORY_LIST, OCCUPATION_LIST } from '../../ApiUrl';
import { authApi } from '../../redux/api/apiConfig';
import Signin from './Signin';
import Signup from './Signup';
import { toast } from 'react-toastify';

const Authentication = () => {
  const [openPage, setopenPage] = useState('signin');
  const [countryList, setCountryList] = useState(JSON.parse(localStorage.getItem('country-list')) || []);
  const [categoryList, setCategoryList] = useState();
  // JSON.parse(localStorage.getItem('category-list')) || []

  const [occupationList, setOccupationList] = useState();
  // JSON.parse(localStorage.getItem('category-list')) || []

  const changepage = (p) => {
    setopenPage(p);
  };

  useEffect(() => {
    getCountryList();
    // getCategoryList();
    // getOccupationList();
  }, []);

  const getCountryList = () => {
    authApi
      .get(`${COUNTRY_LIST}?limit=1000`)
      .then((response) => {
        const {
          data: { results },
        } = response;
        console.log(response);
        setCountryList(results);
        localStorage.setItem('country-list', JSON.stringify(results));
        // console.log('resoi')
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.message || 'Something went wrong');
      });
  };

  const getOccupationList = async () => {
    const response = await axios.get(OCCUPATION_LIST);
    const {
      data: { data },
    } = response;

    console.log(data);
    setOccupationList(data);
    localStorage.setItem('occupation-list', JSON.stringify(data));
  };

  const getCategoryList = () => {
    authApi
      .get(CATEGORY_LIST)
      .then((response) => {
        const {
          data: { data },
        } = response;
        setCategoryList(data);
        localStorage.setItem('category-list', JSON.stringify(data));
      })
      .catch((error) => {
        toast.error(error?.data?.data?.message || 'Something went wrong');
        // toast.error(error.response.data.message || 'Something went wrong');
      });
  };

  return (
    <>
      {openPage === 'signin' && <Signin changepage={changepage} />}
      {openPage === 'signup' && (
        // <Signin changepage={changepage} />
        <Signup
          countryList={countryList}
          changepage={changepage}
          // categoryList={categoryList}
          // occupationList={occupationList}
        />
      )}
    </>
  );
};

export default Authentication;

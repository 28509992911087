import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, DialogActions, Stack, Typography } from '@mui/material';
import { TextField, MenuItem } from '@material-ui/core';
import { Button } from 'rsuite';

const StatusModal = ({ isOpen, onClose, updateClickHandler, mtoPatchLoading, canChangeTo = [] }) => {
  console.log(canChangeTo);
  const [selectedStatus, setSelectedStatus] = useState('default');
  const [remarks, setRemarks] = useState('');

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={'sm'}
      onClose={onClose}
      scroll={'body'}
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
        },
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>Update Status</DialogTitle>
      <DialogContent sx={{ paddingBottom: '0 !important' }}>
        <Box
          sx={{
            display: 'grid',
            columnGap: 2,
            rowGap: 2,
            gridTemplateColumns: { xs: 'repeat(1, 1fr)' },
          }}
        >
          <Stack
            direction={'column'}
            sx={{
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              flex: 1,
            }}
            spacing={0.5}
          >
            <Typography>Status</Typography>
            <TextField
              select
              key='status'
              type={'text'}
              fullWidth
              variant='outlined'
              id='status'
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
            >
              <MenuItem value={'default'} id={'default'}>
                {'Select Status'}
              </MenuItem>
              {canChangeTo?.map((item) => (
                <MenuItem value={item.id} id={item.id}>
                  {item.actionable_name}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <Stack
            direction={'column'}
            sx={{
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              flex: 1,
            }}
            spacing={0.5}
          >
            <Typography>Remarks</Typography>
            <TextField
              multiline
              minRows={3}
              maxRows={3}
              key='remarks'
              type={'text'}
              fullWidth
              variant='outlined'
              id='remarks'
              onChange={(e) => setRemarks(e.target.value)}
              // sx={{ flexGrow: 1, width: '100%' }}
            />
          </Stack>
        </Box>

        <DialogActions sx={{ justifyContent: 'space-between' }}>
          <Button className='btn white-btn action-button ' onClick={onClose} appearance='subtle'>
            Cancel
          </Button>
          <Button
            onClick={() => updateClickHandler(selectedStatus, remarks)}
            loading={mtoPatchLoading}
            disabled={!selectedStatus || selectedStatus === 'default'}
            className={`btn action-button primary_color text-white`}
            appearance='primary'
          >
            Next
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default StatusModal;

import React, { useState, useEffect } from 'react';
// import Button from "@mui/material/Button";
import { Button } from 'rsuite';
import { Col, Row } from '@themesberg/react-bootstrap';
// import TextField from "@mui/material/TextField";
import TextField from '@material-ui/core/TextField';
import { useFormik } from 'formik';
import * as yup from 'yup';
// import * as API from "../../ApiUrl";
import axios from 'axios';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { COUNTER } from '../../../../ApiUrl';
import { PAYSTRING_DOMAIN } from '../../../../ApiUrl';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { textFieldLabelProps } from '../../../../utils/common';

const validationSchema = yup.object({
  name: yup.string('Enter your couter name').min(2, 'Please enter valid counter name').required('counter name is required!'),

  remarks: yup.string('Enter your remarks').required('remarks is required'),
});

function EditCounter({
  data,
  open,
  handleeditCounter,
  onClose: handleClose,
  id,
  // loading,
}) {
  // If its null and new value not entered, formik  throws error, because its expecting a string value
  data = { ...data, remarks: data.remarks === null ? '' : data.remarks };

  const [loading, setloading] = useState(false);
  const [payDomain, setPayDomain] = useState('');

  let token = localStorage.getItem('token');
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };

  useEffect(() => {
    setPayDomain(data.paystring.split('$')[1]);
  }, [data]);

  const onSubmit = async (values) => {
    const data = {
      ...values,
    };
    setloading(true);
    try {
      const response = await axios.patch(`${COUNTER}${id}/`, data, config);
      console.log('trans list show ', response);
      toast.success('Counter successfully Updated');
      handleeditCounter();
      // formik.
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setloading(false);
  };
  const formik = useFormik({
    initialValues: { ...data, paystring: data.paystring.split('$')[0] },
    validateOnBlur: true,
    validateOnMount: true,
    // isInitialValid:false,
    onSubmit,
    validationSchema: validationSchema,
  });

  return (
    <Dialog
      open={open}
      //   fullWidth={"true"}
      maxWidth={'sm'}
      onClose={handleClose}
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
          width: '480px',
          minHeight: '435px',
          maxHeight: '80vh',
        },
      }}
    >
      <DialogTitle className='text-center'>
        <p className='dialog-title'> Edit Counter</p>
      </DialogTitle>
      <hr className='hr' />
      <DialogContent className='flex-col'>
        <form onSubmit={() => {}}>
          <Row>
            <Col xl={12} xs={12}>
              <TextField
                className='w-100 mb-4'
                variant='outlined'
                name='name'
                id='name'
                label='Counter Name'
                InputLabelProps={textFieldLabelProps}
                type='text'
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Col>
            <Col xl={12} xs={12}>
              <TextField
                // onChange={(e) => handleChange(e)}
                className='w-100 mb-4'
                variant='outlined'
                name='paystring'
                id='paystring'
                // placeholder="paystring"
                InputLabelProps={textFieldLabelProps}
                label='Paystring'
                type='text'
                prefix='$spendthebits.com'
                value={formik.values.paystring}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.paystring && Boolean(formik.errors.paystring)}
                helperText={`${formik.values.paystring ? formik.values.paystring : ''}$${payDomain}`}
              />
            </Col>

            <Col xl={12} xs={12}>
              <TextField
                className='w-100 mb-4'
                variant='outlined'
                name='remarks'
                id='remarks'
                multiline
                rows={6}
                label='Remarks'
                InputLabelProps={textFieldLabelProps}
                type='text'
                value={formik.values.remarks || ''}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.remarks && Boolean(formik.errors.remarks)}
                helperText={formik.touched.remarks && formik.errors.remarks}
              />
            </Col>
          </Row>
        </form>
      </DialogContent>
      <hr className='hr' />
      <DialogActions className='footer-content '>
        <Button onClick={handleClose} className='btn white-btn action-button basis-30' appearance='subtle'>
          Cancel
        </Button>
        <Button
          className={`btn action-button primary_color text-white basis-70 ${!formik.isValid ? 'disabled' : null}`}
          appearance='primary'
          loading={loading}
          onClick={formik.handleSubmit}
          disabled={!formik.isValid || loading}
        >
          {!loading ? 'Update Counter' : 'loading...'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditCounter;

import React, { useState } from 'react';
import { Button } from 'rsuite';
import { Col, Row } from '@themesberg/react-bootstrap';
import _ from 'lodash';
import { InputAdornment, TextField, IconButton, Tooltip } from '@material-ui/core';
import InfoRounded from '@material-ui/icons/InfoRounded';
import { useFormik } from 'formik';
import * as yup from 'yup';
import 'react-toastify/dist/ReactToastify.css';
import PhoneInput from 'react-phone-input-2';
import axios from 'axios';
import { toast } from 'react-toastify';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { STORE_LIST } from '../../../../../ApiUrl';
import { textFieldLabelProps } from '../../../../../utils/common';

const validationSchema = yup.object({
  name: yup.string('Enter your store name').min(2, 'Please enter valid store name').required('Store name is required!'),

  phone: yup
    .string('Enter your phonenumber')
    // .matches(numricRegex, "Only Number Required")
    .min(10, 'Enter Valid Number')
    .max(12, 'Phone Number Too Long ! Not Valid')
    .required('Phone Number is required'),

  postal_code: yup
    .string('Enter your postal code')
    .required('Postal code is required')
    .matches('^[a-zA-Z0-9]*$', 'Only alphanumeric characters allowed.'),

  address: yup.string('Enter your Address').min(5, 'Too Short').max(300, 'Too Long ').required('Address is required'),

  city: yup.string('Enter your city').required('City is required'),
});

function UpdateStore({ open, handleaddStore, onClose: handleClose, handleUpdateStore, loading, storeData, error = {} }) {
  const countryList = JSON.parse(localStorage.getItem('country-list')) || [];
  console.log(countryList);

  // const {
  //   name: ename = [],
  //   phone: ephone = [],
  //   postal_code: epostal_code = [],
  //   address: eddress = [],
  //   city: ecity = [],
  // } = error;

  // const onSubmit = async (values) => {
  //   const value = {
  //     ...values,
  //   };
  //   console.log(value);
  //   handleaddStore(value);
  // };

  const onSubmit = async (values) => {
    const value = {
      ...values,
    };
    let token = localStorage.getItem('token');

    try {
      // console.log("user list token", token );
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.patch(`${STORE_LIST}${storeData.id}/`, value, config);
      console.log('trans list show ', response);
      toast.success('Store successfully Updated');
      handleUpdateStore();
    } catch (error) {
      toast.error(error.response.data.store_id[0]);
      console.log('trans list error ', error.response.data);
    }
    console.log(value);
  };

  console.log(storeData);

  const initialData = {
    name: storeData.name,
    store_id: storeData.store_id,
    phone: storeData.phone,
    address: storeData.address,
    city: storeData.city,
    postal_code: storeData.postal_code,
  };
  console.log(initialData);

  const formik = useFormik({
    initialValues: initialData,
    validateOnBlur: true,
    onSubmit,
    validationSchema: validationSchema,
    // isInitialValid: false,
    validateOnMount: true,
  });

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={handleClose}
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
          width: '600px',
          minHeight: '435px',
          maxHeight: '80vh',
        },
      }}
    >
      <DialogTitle className='text-center'>
        <p className='dialog-title'>Update Store Details</p>
      </DialogTitle>
      <hr className='hr' />
      <form onSubmit={() => {}}>
        <DialogContent className='flex-col'>
          {/* <DialogContentText>
            To subscribe to this website, please enter your email address here. We
            will send updates occasionally.
          </DialogContentText> */}

          <Row>
            <Col xl={6} xs={12}>
              <TextField
                className='w-100 mb-3'
                // value={data.name}
                variant='outlined'
                name='name'
                id='name'
                InputLabelProps={textFieldLabelProps}
                label='Store Name'
                type='text'
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Col>
            <Col xl={6} xs={12}>
              <TextField
                disabled
                className='w-100 mb-3'
                variant='outlined'
                name='store_id'
                id='store_id'
                InputLabelProps={textFieldLabelProps}
                label='Store ID'
                type='text'
                // helperText="This is your unique store id which will be prefixed to all paystrings generated. No special chars only alphanumeric allowed"
                value={formik.values.store_id}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment>
                      <Tooltip
                        arrow
                        title='This is your unique store id which will be prefixed to all paystrings generated. No special chars only alphanumeric allowed'
                      >
                        <IconButton>
                          <InfoRounded />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </Col>
            <Col xl={6} xs={12}>
              <TextField
                className='w-100 mb-3'
                variant='outlined'
                name='address'
                id='address'
                InputLabelProps={textFieldLabelProps}
                label='Address'
                type='text'
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address && formik.errors.address}
              />
            </Col>
            <Col xl={6} xs={12}>
              <TextField
                className='w-100 mb-3'
                variant='outlined'
                name='city'
                id='city'
                InputLabelProps={textFieldLabelProps}
                label='City'
                type='text'
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Col>
            <Col xl={6} xs={12} className='mb-4'>
              <PhoneInput
                containerClass='mt-0'
                onlyCountries={_.map(countryList, (country) => country.alpha2code.toLowerCase())}
                value={formik.values.phone}
                country='ca'
                inputProps={{
                  name: 'Phone Number',
                  required: true,
                }}
                inputStyle={{
                  height: 55,
                  borderRadius: 5,
                  width: '100%',
                  margin: 0,
                }}
                autoFormat={false}
                id='phone'
                name='phone'
                countryCodeEditable={false}
                className=' bg-white signup-phoneinput'
                onChange={(phone) => {
                  // formik.handleChange expects a event, but PhoneInput is just giving the value, therefore made an event and sent to handleChange
                  let e = {
                    target: {
                      name: 'phone',
                      value: phone,
                    },
                  };
                  formik.handleChange(e);
                }}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Col>
            <Col xl={6} xs={12}>
              <TextField
                className='w-100 mb-3'
                variant='outlined'
                name='postal_code'
                id='postal_code'
                InputLabelProps={textFieldLabelProps}
                label='Postal code'
                type='text'
                value={formik.values.postal_code}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.postal_code && Boolean(formik.errors.postal_code)}
                helperText={formik.touched.postal_code && formik.errors.postal_code}
              />
            </Col>
          </Row>
        </DialogContent>
        <hr className='hr' />
        <DialogActions className='footer-content'>
          <Button className='btn white-btn action-button basis-30' onClick={handleClose}>
            Cancel
          </Button>
          <Button
            className={`btn action-button primary_color text-white basis-70 ${!formik.isValid ? 'disabled' : null}`}
            disabled={!formik.isValid || loading}
            loading={loading}
            onClick={formik.handleSubmit}
          >
            {!loading ? 'Update Store' : 'loading...'}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default UpdateStore;

import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { ButtonPrimary, Iconify } from '../../../components';
import { ViewDetails } from './viewDetails/ViewDetails';
import { DocumentDetails } from './documentDetails';
import StatusHistory from './statusHistory';
import { capitalize } from 'lodash';
import { Box, Tab, Tabs, Stack, Skeleton } from '@mui/material';
import back from '../../../assets/img/icons/back.svg';
import { Button } from 'rsuite';
import ReactToPrint from 'react-to-print';
import { useSelector } from 'react-redux';
import PreviewPDF from '../../../components/previewPDF/PreviewPDF';
import { toast } from 'react-toastify';
import { merchantApi } from '../../../redux/api/apiConfig';
import { REMITTANCE_INCOMING, REMITTANCE_OUTGOING, TRANSACTION } from '../../../ApiUrl';
import { Routes } from '../../../routes';
import StatusModal from '../inward/components/statusModal/StatusModal';
import AccountSelectionModal from '../outward/components/accountSelectionModal/AccountSelectionModal';
import PassphraseModal from '../outward/components/addRemittance/PassphraseModal';
import { send } from '../../../utils/xrpl_functions';
import usePageTitle from '../../../hooks/usePageTitle';

const RemittanceDetails = ({ type }) => {
  const [currentTab, setCurrentTab] = useState('details');
  const [transaction, setTransaction] = useState(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [mtoPatchLoading, setMtoPatchLoading] = useState(false);
  const [showAccountSelectionModal, setShowAccountSelectionModal] = useState(false);

  const [showPassphraseModal, setShowPassphraseModal] = useState(false);
  const [passphraseLoading, setPassphraseLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const { walletsReducer } = useSelector((state) => state);
  const { wallets = [], coins = [] } = walletsReducer;

  let componentRef = useRef();
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const { id } = params;
  const isInward = location.pathname.includes('inward');

  usePageTitle('Remittance Details');

  const getTransasction = useCallback(async () => {
    const URL = isInward ? REMITTANCE_INCOMING : REMITTANCE_OUTGOING;
    try {
      setLoading(true);
      const response = await merchantApi.get(`${URL}${id}/`);
      setTransaction(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.data?.data?.message || 'Something went wrong');
    }
  }, [id, isInward]);

  useEffect(() => {
    getTransasction();
    if (location?.state?.isEdit) {
      setCurrentTab('documents');
    }
  }, [id, getTransasction, location]);

  const PREVIEW_TABS = [
    {
      value: 'details',
      icon: <Iconify icon={'ic:round-account-box'} width={20} height={20} />,
      component: <ViewDetails ref={componentRef} transaction={transaction} isInward={isInward} />,
    },
    {
      value: 'documents',
      icon: <Iconify icon={'ion:documents'} width={20} height={20} />,
      component: <DocumentDetails transaction={transaction} getTransasction={getTransasction} isInward={isInward} />,
    },
    {
      value: 'status history',
      icon: <Iconify icon={'material-symbols:history-rounded'} width={20} height={20} />,
      component: <StatusHistory statuses={transaction?.status_history} type={isInward ? 'inward' : 'outward'} />,
    },
  ];

  const backClickHandler = () => {
    history.goBack();
  };

  const currentStatusValue = transaction?.current_status?.value;

  const toggleStatusModal = () => {
    setShowStatusModal((state) => !state);
  };
  const toggleAccountSelectionModal = () => {
    setShowAccountSelectionModal((prev) => !prev);
  };
  const togglePassphraseModal = () => {
    setShowPassphraseModal((state) => !state);
  };

  const approveClickHandler = () => {
    if (currentStatusValue === 'need_mto_approval') {
      toggleStatusModal();
    } else if (currentStatusValue === 'approved_remittance') {
      toggleAccountSelectionModal();
    }
  };

  const updateClickHandler = async (status, remarks) => {
    try {
      setMtoPatchLoading(true);
      const response = await merchantApi.patch(`${REMITTANCE_OUTGOING}${transaction.id}/`, {
        status,
        remarks,
      });
      setMtoPatchLoading(false);
      toggleStatusModal();
      if (response.status === 200) {
        toggleAccountSelectionModal();
        // togglePassphraseModal();
      }
    } catch (err) {
      setMtoPatchLoading(false);
      toast.error(err?.data?.data?.message || 'Something went wrong');
    }
  };

  const accountSelectionNext = () => {
    toggleAccountSelectionModal();
    togglePassphraseModal();
  };

  const toggleModals = () => {
    togglePassphraseModal();
  };

  const passphraseSubmitHandler = async (passphrase) => {
    setPassphraseLoading(true);
    console.log(transaction);
    const { send_amount, receive_amount, receive_currency, send_currency, receipt_mto, sender, receiver, receiver_commission } = transaction;

    const {
      address: fromAddress,
      coin: { issuer_address: issuerSend, symbol: senderCurrency },
    } = wallets.find((wallet) => wallet.coin.id === send_currency.id);
    const {
      address: toAddress,
      coin: { issuer_address: issuerRecv, symbol: receiverCurrency },
    } = receipt_mto.wallet.find((wallet) => wallet.coin.id === receive_currency.id);
    try {
      const blob = await send(
        fromAddress,
        toAddress,
        passphrase,
        Number(send_amount) + Number(receiver_commission),
        Number(send_amount) + Number(receiver_commission),
        senderCurrency,
        senderCurrency,
        issuerSend,
        issuerSend,
        sender.xrpl_tag,
        receiver.xrpl_tag
      );
      if (blob) {
        const response = await merchantApi.post(TRANSACTION, {
          remittance: transaction.id,
          blob,
        });
        toast.success('Remittance signed successfully');
        togglePassphraseModal();
        // getTransasction();
        history.push(Routes.OutwardRemittance.path);
      }
    } catch (err) {
      toast.error(err?.data?.data?.message || 'Something went wrong');
    } finally {
      setPassphraseLoading(false);
    }
  };

  return (
    <Box sx={{ padding: '24px ' }}>
      {loading ? (
        <Skeleton variant='rounded' animation={'wave'} height={1500} />
      ) : (
        <>
          <Stack justifyContent={'space-between'} direction={'row'}>
            <span onClick={backClickHandler} className='back mb-4'>
              <img src={back} alt='back' />
              <span>Back</span>
            </span>
            {currentStatusValue === 'approved_remittance' || currentStatusValue === 'need_mto_approval' ? (
              <ButtonPrimary onClick={approveClickHandler}>
                {currentStatusValue === 'need_mto_approval' ? 'Approve Remittance ' : 'Sign Transaction'}
              </ButtonPrimary>
            ) : (
              ''
            )}
          </Stack>

          <Stack justifyContent={'space-between'} alignItems={{ md: 'center', xs: 'start' }} direction={{ md: 'row', xs: 'column' }} spacing={2}>
            <Tabs
              allowScrollButtonsMobile
              variant='scrollable'
              scrollButtons='auto'
              value={currentTab}
              onChange={(e, newValue) => setCurrentTab(newValue)}
            >
              {PREVIEW_TABS.map((tab) => (
                <Tab disableRipple key={tab.value} label={capitalize(tab.value)} iconPosition='start' icon={tab.icon} value={tab.value} />
              ))}
            </Tabs>
            {currentTab === 'details' && transaction ? (
              <Stack spacing={2} direction={'row'}>
                <ReactToPrint
                  trigger={() => (
                    <Button className='btn load text-white'>
                      <Iconify sx={{ mr: 1 }} icon={'eva:printer-outline'} />
                      Print
                    </Button>
                  )}
                  content={() => componentRef.current}
                  documentTitle={transaction?.reference_number}
                />

                <PreviewPDF coins={coins} transaction={transaction} isInward={isInward} />
              </Stack>
            ) : (
              ''
            )}
          </Stack>
          {transaction &&
            PREVIEW_TABS.map((tab) => {
              const isMatched = tab.value === currentTab;
              return isMatched && <Box key={tab.value}>{tab.component}</Box>;
            })}
        </>
      )}

      {showStatusModal && (
        <StatusModal
          isOpen={showStatusModal}
          onClose={toggleStatusModal}
          updateClickHandler={updateClickHandler}
          mtoPatchLoading={mtoPatchLoading}
          status={transaction?.current_status}
          canChangeTo={transaction?.current_status?.sender_can_change_to}
        />
      )}

      {showAccountSelectionModal && (
        <AccountSelectionModal
          isOpen={showAccountSelectionModal}
          onClose={toggleAccountSelectionModal}
          nextClickHandler={accountSelectionNext}
          transaction={transaction}
        />
      )}

      {showPassphraseModal && (
        <PassphraseModal
          isOpen={showPassphraseModal}
          onClose={togglePassphraseModal}
          backClickHandler={toggleModals}
          submitClickHandler={passphraseSubmitHandler}
          passphraseLoading={passphraseLoading}
        />
      )}
    </Box>
  );
};

export default RemittanceDetails;

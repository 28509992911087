import React, { useEffect, useCallback, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
// @mui
import { Box, Divider, Stack, IconButton, Typography } from '@mui/material';
// redux
import { useDispatch, useSelector } from 'react-redux';
import { styled, alpha } from '@mui/material/styles';

import { useDropzone } from 'react-dropzone';
import { SingleFilePreview } from '../../../components/upload';
import { Iconify } from '../../../components';
// routes
//
import ChatRoom from './ChatRoom';
import ChatMessageList from './ChatMessageList';
import ChatHeaderDetail from './ChatHeaderDetail';
import ChatMessageInput from './ChatMessageInput';
import ChatHeaderCompose from './ChatHeaderCompose';
import { sendMessage } from '../../../redux/actions/chat';
import ChatConversationItemSkeleton from './ChatConversationItemSkeleton';
import { allowedMediaTypes } from '../../../utils/common';
import Image from '../../../components/image/Image';
import bg from '../../../assets/illustrations/beginChat.svg';
import { toast } from 'react-toastify';
// ----------------------------------------------------------------------
const StyledBackground = styled('div')(({ theme }) => ({
  outline: 'none',
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${alpha(theme.palette.grey[500], 0.32)}`,
  '&:hover': {
    opacity: 0.72,
  },
}));

export default function ChatWindow({ selectedConversation, openOnly }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);
  const { conversationKey } = useParams();
  const { participants, activeConversationId, isLoading } = useSelector(
    (state) => state.chat
  );

  const handleDrop = useCallback((acceptedFiles) => {
    console.log(acceptedFiles[0]);
    const file = acceptedFiles[0];

    const newFile = Object.assign(file, {
      preview: URL.createObjectURL(file),
    });

    if (file) {
      setFile(newFile);
    }
  }, []);

  const onDelete = () => {
    setFile(null);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    noClick: true,
    onDrop: handleDrop,
    accept: allowedMediaTypes,
  });

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationKey]);

  useEffect(() => {}, [activeConversationId]);

  const handleSendMessage = async (value) => {
    let data = {
      ...value,
      agent: selectedConversation.assigned_agent,
      user: null,
    };
    if (file) {
      let fileData = new FormData();
      fileData.append('ticket', value.ticket);
      fileData.append('message', value.message);
      fileData.append('attachment', file);
      fileData.append('user', null);
      data = fileData;
    }
    try {
      dispatch(sendMessage(data, selectedConversation.id, file ? true : false));
      setFile(null);
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      console.error(error);
    }
  };

  const detailView = !!selectedConversation.id && !!activeConversationId;

  const loading =
    isLoading || (selectedConversation.id === '' && activeConversationId);

  return (
    <Stack sx={{ flexGrow: 1, minWidth: '1px' }}>
      {loading ? (
        <ChatConversationItemSkeleton titleWidth="20%" descriptionWidth="40%" />
      ) : detailView ? (
        <ChatHeaderDetail
          conversation={selectedConversation}
          openOnly={openOnly}
        />
      ) : (
        // <ChatHeaderCompose
        //   recipients={recipients}
        //   contacts={Object.values(contacts.byId)}
        //   onAddRecipients={handleAddRecipients}
        // />
        ''
      )}

      <Divider />

      <Box sx={{ flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
        <Stack
          sx={{
            flexGrow: 1,
            ...(isDragActive && {
              opacity: 0.72,
            }),
          }}
        >
          {/* <ChatMessageList
            getRootProps={getRootProps}
            conversation={selectedConversation}
          /> */}
          {selectedConversation.id ? (
            <ChatMessageList
              getRootProps={getRootProps}
              conversation={selectedConversation}
            />
          ) : (
            // <Stack sx={{ p: 3 }}>
            <>
              <Image src={bg} sx={{ pt: 1, px: 1 }} />
              <Typography variant="body2" sx={{ p: 2, textAlign: 'center' }}>
                Select a conversation to start chatting.
              </Typography>
            </>
            // </Stack>
          )}

          <Divider />
          {file && (
            <StyledBackground
              sx={{
                position: 'relative',
                ...(file && {
                  padding: '12% 0',
                }),
              }}
            >
              <SingleFilePreview file={file} />
              <IconButton
                size="small"
                onClick={onDelete}
                sx={{
                  top: 16,
                  right: 16,
                  zIndex: 9,
                  position: 'absolute',
                  color: (theme) => alpha(theme.palette.common.white, 0.8),
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                  '&:hover': {
                    bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                  },
                }}
              >
                <Iconify icon="eva:close-fill" width={18} />
              </IconButton>
            </StyledBackground>
          )}

          <ChatMessageInput
            conversationId={activeConversationId}
            onSend={handleSendMessage}
            disabled={selectedConversation?.status === 'closed'}
            getInputProps={getInputProps}
            file={file}
          />
        </Stack>

        {/* {mode === 'DETAIL' && (
          <ChatRoom
            conversation={conversation}
            participants={displayParticipants}
          />
        )} */}
      </Box>
    </Stack>
  );
}

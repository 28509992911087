import axios from 'axios';
import { BASE_URL } from '../../ApiUrl';

//Auth api
export const authApi = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

authApi.interceptors.request.use(
  async function (options) {
    return options;
  },
  function (error) {
    return Promise.reject(error);
  }
);

authApi.interceptors.response.use(
  async function (options) {
    return options;
  },
  function (error) {
    console.log(error);
    return Promise.reject({ error, data: error.response });
  }
);

//Authorized api
export const merchantApi = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

merchantApi.interceptors.request.use(
  async function (options) {
    // console.log(options);
    // options?.params &&
    // console.log(options, Object.hasOwn(options?.params, 'from_date'));
    options.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    if (
      options.params &&
      (Object.hasOwn(options.params, 'created_date_after') ||
        Object.hasOwn(options.params, 'from_date') ||
        Object.hasOwn(options.params, 'created_date_before') ||
        Object.hasOwn(options.params, 'to_date'))
    ) {
      options.params['tz_offset'] = new Date().getTimezoneOffset() / 60;
    }
    return options;
  },
  function (error) {
    return Promise.reject(error);
  }
);

merchantApi.interceptors.response.use(
  async function (options) {
    return options;
  },
  function (error) {
    console.log(error);
    return Promise.reject({ error, data: error.response });
  }
);

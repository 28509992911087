import PropTypes from 'prop-types';
import React from 'react';
import { formatDistanceToNowStrict } from 'date-fns';
import { Label } from '../../../components';
import BadgeStatus from '../../../components/BadgeStatus';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Avatar, ListItemText, ListItemAvatar, ListItemButton, Stack, Tooltip } from '@mui/material';
import createAvatar from '../../../utils/createAvatar';
//
// ----------------------------------------------------------------------

const AVATAR_SIZE = 48;
const AVATAR_SIZE_GROUP = 32;

const RootStyle = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  // transition: theme.transitions.create('all'),
}));

const AvatarWrapperStyle = styled('div')({
  position: 'relative',
  width: AVATAR_SIZE,
  height: AVATAR_SIZE,
  '& .MuiAvatar-img': { borderRadius: '50%' },
  '& .MuiAvatar-root': { width: '100%', height: '100%' },
});

// ----------------------------------------------------------------------

const getDetails = (conversation, currentUserId) => {
  const subject = conversation.subject;
  let displayText = '';
  let isUnread = true;
  const lastMessage = conversation?.replies?.[conversation.replies.length - 1];

  if (lastMessage) {
    const sender = lastMessage?.agent ? 'You: ' : ' ';
    const message = lastMessage?.attachments.length > 0 ? 'Sent a photo' : lastMessage.message;
    displayText = `${sender}${message}`;
    isUnread = !lastMessage.is_read;
  } else {
    displayText = conversation.message;
    isUnread = !conversation.is_read;
  }
  const { first_name, last_name, avatar = '' } = conversation.user.data;
  const userName = `${first_name} ${last_name}`;

  return { subject, displayText, isUnread, userName, avatar };
};

ChatConversationItem.propTypes = {
  isSelected: PropTypes.bool,
  conversation: PropTypes.object.isRequired,
  isOpenSidebar: PropTypes.bool,
  onSelectConversation: PropTypes.func,
};

export default function ChatConversationItem({ isSelected, conversation, isOpenSidebar, onSelectConversation }) {
  const details = getDetails(conversation, '1');

  const displayLastActivity = conversation?.replies?.[conversation.replies.length - 1]?.created_date ?? conversation?.created_date;

  const isUnread = !conversation.is_read;
  const isTicketClosed = conversation.status !== 'open';

  return (
    <RootStyle
      onClick={onSelectConversation}
      divider={isOpenSidebar}
      sx={{
        ...(isSelected && { bgcolor: 'action.selected' }),
        flexDirection: 'column',
      }}
    >
      <Stack sx={{ flexDirection: 'row', width: '100%', alignItems: 'center' }}>
        <Tooltip title={conversation.reference_no}>
          <ListItemAvatar>
            <Avatar alt={details.userName} sx={{ mr: 1, bgcolor: createAvatar(details.userName).color }}>
              {createAvatar(details.userName).name}
            </Avatar>
          </ListItemAvatar>
        </Tooltip>
        {isOpenSidebar && (
          <>
            <ListItemText
              primary={details.userName}
              primaryTypographyProps={{
                noWrap: true,
                variant: 'subtitle2',
              }}
              secondary={details.displayText}
              secondaryTypographyProps={{
                noWrap: true,
                variant: isUnread ? 'subtitle2' : 'body2',
                color: isUnread ? 'textPrimary' : 'textSecondary',
              }}
            />

            <Box
              sx={{
                ml: 2,
                height: 44,
                display: 'flex',
                alignItems: 'flex-end',
                flexDirection: 'column',
              }}
            >
              <Box
                sx={{
                  mb: 1.25,
                  fontSize: 12,
                  lineHeight: '22px',
                  whiteSpace: 'nowrap',
                  color: 'text.disabled',
                }}
              >
                {formatDistanceToNowStrict(new Date(displayLastActivity), {
                  addSuffix: false,
                })}
              </Box>
              {isUnread && <BadgeStatus status='unread' size='small' />}
            </Box>
          </>
        )}
      </Stack>

      {isOpenSidebar && (
        <Stack
          sx={{
            width: 1,
            flexDirection: 'row',
            justifyContent: 'space-between',
            mt: 1,
          }}
        >
          <Stack
            sx={{
              flexDirection: 'row',
              justifyContent: 'flex-start',
            }}
          >
            <Label
              variant={'ghost'}
              color={conversation.reference_no.split('-')[0] === 'SUP' ? 'application submitted' : 'pending'}
              sx={{
                textTransform: 'capitalize',
                width: 'fit-content',
                marginRight: '10px',
              }}
            >
              {conversation.reference_no}
            </Label>
            {!conversation.is_read && (
              <Label
                variant={'ghost'}
                color={'complete'}
                sx={{
                  textTransform: 'capitalize',
                  width: 'fit-content',
                  marginRight: '10px',
                }}
              >
                New
              </Label>
            )}
          </Stack>
          <Label
            variant={'ghost'}
            color={isTicketClosed ? 'complete' : 'rejected'}
            sx={{
              textTransform: 'capitalize',
              width: 'fit-content',
              marginRight: '10px',
            }}
          >
            {isTicketClosed ? 'Closed' : 'Open'}
          </Label>
        </Stack>
      )}
    </RootStyle>
  );
}

import React, { useState } from 'react';
import { styled } from '@material-ui/core/styles';
import { Typography, Stack, Box, Paper } from '@mui/material';
import { AddNewCard } from '../../../../../components/addNewCard';
import { UploadDocument } from '../../../../../components/uploadDocumentModal';
import { DocumentCard } from '../../../../../components/documentCard';

const MyPaper = styled(Paper)({
  borderRadius: 8,
  borderColor: '#000',
  backgroundColor: '#F0F0F0',
  padding: '1rem',
});
const DocumentDetails = ({ client, getClient }) => {
  const [showDocumentUpload, setShowDocumentUpload] = useState(false);

  const { sender_document } = client;

  const toggleDocumentUpload = () => {
    setShowDocumentUpload((prevState) => !prevState);
  };

  return (
    <>
      <Box>
        <MyPaper sx={{ flex: '1' }} elevation={0}>
          <Stack direction={'column'} spacing={2} sx={{ flex: 1 }}>
            <Typography variant={'h6'}>Documents</Typography>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 200px))',
                gridGap: '1rem',
                width: '100%',
              }}
            >
              <>
                {sender_document?.map((document, i) => {
                  return <DocumentCard document={document} canDelete={true} onDocumentDelete={getClient} />;
                })}

                <AddNewCard onClick={toggleDocumentUpload} />
              </>
            </Box>
          </Stack>
        </MyPaper>
      </Box>
      {showDocumentUpload && (
        <UploadDocument isOpen={showDocumentUpload} onClose={toggleDocumentUpload} senderId={client.id} getSenderAccounts={getClient} />
      )}
    </>
  );
};

export default DocumentDetails;

import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Dialog, DialogTitle, DialogContent, DialogActions, Stack, MenuItem, Alert } from '@mui/material';
import { useFormik } from 'formik';
import { ButtonPrimary, ButtonSecondary, CurrencySelect, TextFieldComponent, TextFieldSelect, Iconify } from '..';
import { BalanceCard } from '../balanceCard';
import { merchantApi } from '../../redux/api/apiConfig';
import { MTO_ACCOUNTS } from '../../ApiUrl';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const Transfer = ({ isOpen, onClose, nextClickHandler, coinToSelect = '' }) => {
  const [accounts, setAccounts] = useState([]);
  const [insufficientBalance, setInsufficientBalance] = useState(false);
  const [wallets, setWallets] = useState([]);
  const [selectedWallet, setSelectedWallet] = useState(null);
  const [spin, setSpin] = useState(0);
  const [loading, setLoading] = useState(false);
  const [yupObject, setYupObject] = useState({
    fromAmount: yup.number('Enter amount').moreThan(0, 'Amount must be greater than 0').required('Amount is required!'),

    fromCurrency: yup.string('Select currency').required('Currency is required!'),

    from: yup.string('Select sender account').required('Sender account is required!'),

    to: yup.string('Select receiver account').required('Receiver account is required!'),
  });
  const [validationSchema, setValidationSchema] = useState(yup.object(yupObject));

  const mtoDefaultCurrency = useSelector((state) => state?.profileReducer?.user?.mto?.currency?.symbol);

  const initializeAccounts = async () => {
    try {
      const accounts = await getAccounts();
      setAccounts(accounts);
      let standByAccount = accounts.find((account) => account.mto_type === 'standby');
      let operatingAccountId = accounts.find((account) => account.mto_type === 'operating')?.id;

      if (operatingAccountId && standByAccount.id) {
        await formik.setValues({ from: standByAccount.id, to: operatingAccountId }, true);
        fromAccountChangeHandler(standByAccount.id, accounts);
      }

      // if (coinToSelect !== '') {
      console.log(mtoDefaultCurrency, standByAccount.balances);
      formik.setFieldValue('fromCurrency', coinToSelect);
      const defaultWallet = standByAccount.balances.find((wallet) => wallet.coin.symbol === mtoDefaultCurrency);
      formik.setFieldValue('fromCurrency', defaultWallet.coin.id);
      walletChangeHandler(defaultWallet.coin.id, standByAccount.balances);
      // }
    } catch (error) {
      toast.error(error?.data?.data?.message || 'Something went wrong');
      // console.log(error);
    }
  };

  const getAccounts = async () => {
    try {
      setLoading(true);
      const response = await merchantApi.get(MTO_ACCOUNTS);
      setLoading(false);
      return response.data.results;
    } catch (error) {
      setLoading(false);
      toast.error(error?.data?.data?.message || 'Something went wrong');
      throw new Error('Something went wrong');
    }
  };

  const refreshBalance = async () => {
    const accounts = await getAccounts();
    let fromAccount = accounts.find((account) => account.id === formik.values.from);
    setWallets(fromAccount.balances);
    walletChangeHandler(formik.values.fromCurrency, fromAccount.balances);
  };

  const onTransfer = async (values) => {
    nextClickHandler({
      from_account: values.from,
      to_account: values.to,
      send_coin: values.fromCurrency,
      send_amount: values.fromAmount,
      receive_coin: values.fromCurrency,
      receive_amount: values.fromAmount,
    });
  };

  useEffect(() => {
    initializeAccounts();
  }, []);

  const formik = useFormik({
    initialValues: {
      fromCurrency: '',
      fromAmount: '',
      from: '',
      to: '',
    },
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: onTransfer,
    // isInitialValid: isEdit,
    validateOnMount: true,
  });

  const handleCloseModal = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    onClose();
  };

  const fromAccountChangeHandler = (id, account = accounts) => {
    console.log(account, id);
    const newWallets = account.find((account) => account.id === id).balances;
    setWallets(newWallets);
    console.log(newWallets);
    setSelectedWallet('');
    formik.setFieldValue('fromAmount', '');
    formik.setFieldValue('fromCurrency', '');
  };

  const walletChangeHandler = async (id, wallet = wallets) => {
    console.log(id, wallet);
    let newSelectedWallet = '';
    if (id != 'default') {
      newSelectedWallet = wallet.find((wallet) => wallet.coin.id === id);
      checkBalance(newSelectedWallet, formik.values.fromAmount);
    }
    console.log(newSelectedWallet);

    setSelectedWallet(newSelectedWallet);
    let newYupObject = {
      ...yupObject,
      fromAmount: yup
        .number('Enter sender amount')
        .required('Sender amount is required.')
        .moreThan(0, `${newSelectedWallet?.coin?.symbol} value must be greater than 0`)
        .max(newSelectedWallet?.balance, 'Insufficient Balance'),
    };
    setYupObject(newYupObject);
    setValidationSchema(yup.object(newYupObject));
  };

  const fromAmountChangeHandler = (value = null) => {
    checkBalance(selectedWallet, value);
  };

  const checkBalance = (wallet, amount) => {
    if (wallet) {
      if (Number(amount) > Number(wallet.balance)) {
        setInsufficientBalance(true);
      } else {
        setInsufficientBalance(false);
      }
    }
  };

  const swapAccounts = () => {
    const { from, to } = formik.values;
    formik.setValues({ from: to, to: from }, true);
    fromAccountChangeHandler(to);
    setSpin((prev) => prev + 1);
  };
  const { from, to } = formik.values;

  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth={'xs'}
      onClose={handleCloseModal}
      scroll={'body'}
      BackdropProps={{ style: { backgroundColor: 'rgba(11, 5, 35, 0.8)' } }}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: '8px',
        },
      }}
    >
      <DialogTitle sx={{ textAlign: 'center' }}>Transfer Funds</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent sx={{ paddingTop: '5px !important' }}>
          <Stack spacing={1} direction={'column'} alignItems={'center'}>
            <TextFieldSelect
              formik={formik}
              name='from'
              type='text'
              label='From'
              options={accounts}
              onChange={(e) => {
                fromAccountChangeHandler(e.target.value);
              }}
              render={(account, i) => (
                <MenuItem key={account.id} value={account.id}>
                  {account.name}
                </MenuItem>
              )}
            />

            <Iconify
              icon={'ic:round-swap-vert'}
              sx={{
                width: '100px',
                height: '50px',
                cursor: 'pointer',
                animation: `${spin !== 0 ? (spin % 2 !== 0 ? 'spin' : 'spin-back') : ''} 0.1s linear 1`,
                '@keyframes spin': {
                  '0%': {
                    transform: 'rotate(0deg)',
                  },
                  '100%': {
                    transform: 'rotate(180deg)',
                  },
                },
                '@keyframes spin-back': {
                  '0%': {
                    transform: 'rotate(180deg)',
                  },
                  '100%': {
                    transform: 'rotate(0deg)',
                  },
                },
              }}
              onClick={swapAccounts}
            />

            <TextFieldSelect
              formik={formik}
              name='to'
              type='text'
              label='To'
              options={accounts}
              render={(account, i) => (
                <MenuItem key={account.id} value={account.id}>
                  {account.name}
                </MenuItem>
              )}
            />
            {from !== '' && to !== '' && from === to && (
              <Alert severity='warning'>Cannot transfer to same account, please select a different account</Alert>
            )}

            <CurrencySelect
              formik={formik}
              options={(() => wallets.filter((wallet) => wallet.coin.symbol === mtoDefaultCurrency))()}
              optionChangeHandler={walletChangeHandler}
              amountName='fromAmount'
              amountChangeHandler={fromAmountChangeHandler}
              currencyName='fromCurrency'
            />

            {selectedWallet && (
              <BalanceCard
                insufficientBalance={insufficientBalance}
                balance={`${Number(selectedWallet.balance).toFixed(selectedWallet.coin.decimal_place)} ${selectedWallet.coin.symbol}`}
                onRefreshClick={refreshBalance}
                loading={loading}
                // sx={{ mt: 3 }}
              />
            )}

            <TextFieldComponent formik={formik} name={'remarks'} label={'Remarks'} type={'text'} multiline maxRows={3} minRows={3} />
          </Stack>
        </DialogContent>
        <DialogActions
          sx={{
            paddingX: '24px',
            paddingBottom: '15px',
          }}
        >
          <ButtonSecondary onClick={onClose}>Cancel</ButtonSecondary>
          <ButtonPrimary
            // onClick={nextClickHandler}
            type={'submit'}
            disabled={!formik.isValid || from === to}
          >
            Next
          </ButtonPrimary>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default Transfer;
